import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckbox, MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule} from '@angular/material/button';
import { RightSidebarComponent } from "./right-sidebar/right-sidebar.component";
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SidebarComponent } from "./sidebar/sidebar.component";
import { HeaderComponent } from "./header/header.component";



@NgModule({
  declarations:[RightSidebarComponent],
  imports: [CommonModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
  ],
  providers: [
    
  ],
  exports:[RightSidebarComponent]
})
export class LayoutModule { }
