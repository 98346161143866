import { AppError } from './app-error';

export class UserMisMatchError extends AppError {
    authError = {
        code: 'auth/user-mismatch',
        message: 'The email does not belong to the currently logged-in user!'
    };
    constructor(public originalError?: any) {
        super(originalError);
        this.originalError = this.authError;

    }

}
