import { Component } from "@angular/core";
import { CommunicatorService, MyServiceEvent, ActiveClient } from './services/communication.service';
import { LoaderService } from './loader/loader.service';

import {
  Event,
  Router,
  NavigationStart,
  NavigationEnd,
  RouterEvent
} from "@angular/router";
import { PlatformLocation } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  currentUrl: string;
  showLoadingIndicatior = true;

  constructor(public _router: Router, location: PlatformLocation, private Comm: CommunicatorService, public loaderService: LoaderService) {

   console.clear(); 

    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicatior = true;
        

        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.showLoadingIndicatior = false;
      }
      window.scrollTo(0, 0);
    });


    console.log("Start Applicaiton")
/*
    this.Comm.onChange.subscribe({
      next: (event: MyServiceEvent) => {
      }

    })
*/


  }





}
