import { data } from './../ProductionUtilities/all-registration-numbers/all-registration-numbers.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
//import { AngularFireStorage } from '@angular/fire/storage';
import { EventEmitter } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { TestCredit, Request, CreditBatch, RegistrationNumber, RegisterNumberBatch, Device, SQACustomer, ControlsBatch, ProficiencyBatch } from '../globals/globals';
import { AuthService } from '../core/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { EmailNotAvailableError } from '../_helpers/email-not-available-error';
import { formatDate } from '@angular/common';
import { merge } from 'd3';
import { catchError, map, take } from 'rxjs/operators';
import { IfStmt } from '@angular/compiler';
//import { AuthService } from '../core/auth.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*,Origin, Content-Type, X-Auth-Token,AUTHORIZATION',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
  })
};


export const signaltype = {

  AUTO_CALIBRATION: 0,
  TEST: 1,
  SELF_TEST: 2,
  TEST_SN: 3,
  TEST_PARAMETERS: 4,
}

export class TestUpdateEvent {
  data: any;
  changeType: string;
}


@Injectable({
  providedIn: 'root'
})



export class HttpService {

  db = firebase.firestore();
  realtimefacilities: Observable<any[]>;
  currentAdmin = {
    uid: '',
    email: '',
    name: '',
    adminType: ''
  };

  onTestDataChange: EventEmitter<TestUpdateEvent> = new EventEmitter<TestUpdateEvent>();
  private apiEP: string;

  constructor(private _http: HttpClient, private afAuth: AngularFireAuth, private afs: AngularFirestore, private authService: AuthService) {

    const hostname = window.location.hostname.toString().toLowerCase();
    if (hostname.includes('localhost')) {
      this.apiEP = 'api'; //"api/backup"
    } else {
      this.apiEP = "https://us-central1-messqasystem.cloudfunctions.net"; // https://us-central1-messqasystem.cloudfunctions.net/backup
    }
    // this.realtimefacilities = this.afs.collection<any>('SQACustomers').valueChanges();
    // this.realtimefacilities.subscribe(rt => {
    //   console.log('real time facilities ', rt);
    // })
    // this.afs.collectionGroup<any>('Tests').valueChanges();
    this.authService.user.subscribe((user) => {
      if (user !== null && user !== undefined) {
        this.currentAdmin.uid = user.uid;
        this.currentAdmin.email = user.email;
        this.currentAdmin.name = user.firstName + ' ' + user.lastName;
        this.currentAdmin.adminType = user.adminType;
        console.log('http id', this.currentAdmin);
      }
    });

  }


  // ====================================================================================================
  // Global and Generic functions
  // ====================================================================================================
  public getCollectionList(collectionpath) {

    // query => {property, value}   i.e. {property: "age"}
    const markers: any = [];

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).get().subscribe(snapshot => {
        snapshot.forEach(doc => {
          markers.push({ id: doc.id, data: doc.data() });

        });
        //return markers;
        res(markers);
        console.log('success')
      })
    })
  }


  public getCollectionData(collectionpath, docid) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(docid).get().subscribe((doc) => {
        if (doc.exists) {
          res({ id: doc.id, data: doc.data() });
        }
        else {
          console.log("No such document!");
          res({ id: undefined, data: undefined });
        }
      });
    });

  }

  public addCollectionItam(collectionpath, data) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).add(data)
        .then((doc) => {
          console.log({ "id": doc.id, "data": data });
          res(doc);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });


  }

  public updateCollectionData(collectionpath, Itemid, itemData) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).set(itemData, { merge: true })
        .then((docRef) => {
          console.log({ "collectionPath": collectionpath, "id": Itemid, "data": itemData });
          res({ id: Itemid, data: itemData });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public deleteCollectionItem(collectionpath, Itemid) {
    return new Promise<any>((res, rej) => {
      this.afs.collection(collectionpath).doc(Itemid).delete()
        .then((docRef) => {
          console.log({ "collectionPath": collectionpath, "id": Itemid });
          res(docRef);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });

  }

  public getTotalNumber(collection) {

    return new Promise<any>((res, rej) => {
      this.afs.collection(collection).get()
        .subscribe(async function (querySnapshot) {
          let totalRequests = querySnapshot.docs.length;
          res({ number: totalRequests });
        });
    });

  }

  private uploadFile(file, ref, filetype: string = 'Image') {

    return new Promise<any>((res, rej) => {
      if (file.type.includes(filetype)) {
        ref.put(file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            res(url);
          }).catch(err => {
            rej(err);
          });
        });
      } else {
        rej("error");
      }

    });

  }
  public uploadFile1(file, ref) {
    console.log(file.type)
    return new Promise<any>((res, rej) => {
     {
        console.log("file", file.type)
        ref.put(file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            res(url);
          }).catch(err => {
            rej(err);
          });
        });
     
      }

    });

  }

  private uploadBase64(base64Data, ref, fileName) {

    console.log(fileName);
    const base64 = base64Data.split(",")[1];

    return new Promise<any>((res, rej) => {

      ref.putString(base64, 'base64').then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          console.log(url);
          res(url);
        }).catch(err => {
          rej(err);
        });
      });

    });



    // return new Promise<any>((res, rej) => {

    //   const uploadTask = ref.putString(base64, 'base64');

    //   uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
    //     (snapshot) => {

    //       const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //       console.log('Upload is ' + progress + '% done');

    //       if(progress == 100){

    //         snapshot.ref.getDownloadURL().then((url) => {            
    //           console.log(url);
    //           res(url);
    //         }).catch(err => {
    //           rej(err);
    //         });

    //       }
    //     },
    //     (error) => {
    //       // upload failed
    //       console.log(error)
    //     },
    //     (snapshot) => {}

    //   );

    // });

  }


  public deleteFileFromStorage(fileName: string) {
    return new Promise<any>(async (resolve, reject) => {
      var ref = await firebase.storage().ref().child(fileName).delete().then((res) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public dataCount() {
    let url = "https://ioanalyticservices-ldoxyjbqhq-uc.a.run.app/getCreditsLength"
    return this._http.get(url);
  }
  public getFileUrl(fileName) {
    return new Promise<any>((res, rej) => {
      var ref = firebase.storage().ref().child(`${fileName}`);
      ref.getDownloadURL().then((url) => {
        res(url);
      }).catch(err => {
        rej(err);
      });
    });
  }

  public getJsonFile(url: string): Promise<any> {
    return new Promise<any>((res, rej) => {
      this._http.get(url).subscribe(
        (data) => {
          res(data);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }

  public getSignalData(url) {

    return new Promise<any>((res, rej) => {
      this._http.get(url, { responseType: 'text' }).subscribe((data) => {
        res(data);
      }
      )

    });

    //return this._http.get(url,{responseType: 'text'});

  }
  public getxlsxfile(url) {
    return new Promise<any>(async (res, rej) => {
      this._http.get(url, { responseType: 'arraybuffer' }).subscribe(async (data) => {
        const arrayBuffer = data as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);
        res(uint8Array);
      }, error => {
        rej(error);
      });
    });
  }


  public getPermissions(adminType) {
    return new Promise<any>((res, rej) => {
      this.getCollectionData(`System/permissions/AdminSite`, adminType).then(permissions => {
        res(permissions);
      }).catch(err => {
        rej(err);
      })
    })
  }

  public async UploadBase64Video(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Visualization/Videos/${currentFacilityId}/` + data.fileName);

    const res = await this.addCollectionItam('SQACustomers/' + currentFacilityId + '/Videos', data);

    return this.uploadBase64(file, ref, data.fileName);

  }

  public async UploadBase64Image(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Visualization/Images/${currentFacilityId}/` + data.fileName);

    const res = await this.addCollectionItam('SQACustomers/' + currentFacilityId + '/Images', data);

    return this.uploadBase64(file, ref, res.id + ".png");

  }

  public UploadVideo(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Visualization/Videos/${currentFacilityId}/` + file.name);

    this.addCollectionItam('SQACustomers/' + currentFacilityId + '/Videos', data);

    return this.uploadFile(file, ref, 'video/mp4');

  }

  public UploadImage(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Visualization/Images/${currentFacilityId}/` + file.name);

    this.addCollectionItam('SQACustomers/' + currentFacilityId + '/Images', data);

    return this.uploadFile(file, ref, 'image/jpeg');

  }


  public UploadSignal(path, signalData) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(path);

    // ref.put(signalData)

    return new Promise<any>((res, rej) => {
      ref.putString(signalData).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          res(url);
        }).catch(err => {
          rej(err);
        });
      });
    });

    //return this.uploadFile(file, ref,'signal');

  }



  // ====================================================================================================
  // End of Global and Generic functions
  // ====================================================================================================

  /*
    public updateItemDatainCollection1(collection1, Itemid, data) {

      let url = "https://us-central1-messqasystem.cloudfunctions.net/UpdateCollectionDataLevel_1?collection1=" + collection1 + "&collection1id=" + Itemid;

      return this._http.post(url, data)
        .subscribe(
          res => {
            console.log(res);
          },
          err => {
            console.log("Error occured");
          }
        )

    }
  */

  // ============================

  public UpdateFacilityType(currentFacilityId, facilityType: string) {
    let data = { customerType: facilityType };
    this.updateCollectionData('SQACustomers', currentFacilityId, data);
  }

  public UpdateFacilityProfile(currentFacilityId, data) {
    return this.updateCollectionData('SQACustomers', currentFacilityId, data);
  }

  public gettestsForPatient(currentFacilityId, patientid) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllTestsforSelectedPatientinFacility?id=" + currentFacilityId + "&patientid=" + patientid;
    // return this._http.get(url);
    return this.getCollectionList('SQACustomers/' + currentFacilityId + '/patients/' + patientid + '/tests');

  }

  public getControltests(currentFacilityId) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllTestsforSelectedPatientinFacility?id=" + currentFacilityId + "&patientid=" + patientid;
    // return this._http.get(url);
    return this.getCollectionList('SQACustomers/' + currentFacilityId + '/controlTests');

  }

  public getallPatientsForFacility(currentFacilityId) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllPatientsforSelectedFacility?id=" + currentFacilityId;
    // return this._http.get(url);
    return this.getCollectionList('SQACustomers/' + currentFacilityId + '/patients');
  }

  public getAllTestsForFacility(currentFacilityId) {
    // let url = `https://us-central1-messqasystem.cloudfunctions.net/getNestedCollection?id=${facilityId}&maincollection=patients&subcollection=tests`;
    // return this._http.get(url);
    // return new Promise<any>((res, rej) => {

    //   this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').get()
    //     .then(async patientsSnapshot => {
    //       let markers: any = [];
    //       for (const patient of patientsSnapshot.docs) {
    //         await this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patient.id).collection('tests').get()
    //           .then(async testSnapshot => {
    //             for (const test of testSnapshot.docs) {
    //               if (test.id !== 'stats') {
    //                 await markers.push({ id: patient.id, data: patient.data(), testid: test.id, testdata: test.data() });
    //               }
    //             }
    //           }).catch(error => {
    //             console.log("Error getting sub-collection documents", error);
    //             rej(error);
    //           })
    //       }
    //       res(await markers);
    //       console.log(await 'success')
    //     })
    //     .catch(error => {
    //       console.log("Error getting documents: ", error);
    //       rej(error);
    //     });
    // });

    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db.collectionGroup('tests').where('facilityId', '==', currentFacilityId).get()
        .then(async tests => {
          if (tests.empty) {
            console.log('No matching documents.');
            // rej('No tests found.');
          }
          for (const test of tests.docs) {
            await markers.push({ testid: test.id, testdata: test.data() });
          }
          res(await markers);
        }).catch(err => {
          rej(err);
        })

    });
  }

  public getFacilityData(currentFacilityId) {
    return this.getCollectionData('SQACustomers', currentFacilityId);
  }

  public getallCustomers() {
    return this.getCollectionList("SQACustomers")
  }

  public getCustomersDistributors(customerTypes: string[] = ['facility']) {

    // query => {property, value}   i.e. {property: "age"}
    const customers: any = [];
    const distribs: any = [];

    return new Promise<any>((res, rej) => {
      this.db.collection('Distributers').get().then(dSnapshot => {
        dSnapshot.forEach(ddoc => {
          let data = ddoc.data();
          data.id = ddoc.id;
          distribs.push(data);
        })
        console.log('distribs ', distribs);
        return distribs;
      }).then(distributers => {
        this.db.collection('SQACustomers').where("customerType", "in", customerTypes).get().then(snapshot => {
          snapshot.forEach(doc => {

            let addData = doc.data();
            if (doc.data().servicePersons !== undefined && doc.data().servicePersons.length > 0) {
              addData.distributers = doc.data().servicePersons.map(pp => ({
                id: pp.id, support: pp.support, order: pp.order, data: distributers.find(obj => {
                  return obj.id === pp.id
                })
              }));
            } else {
              addData.distributers = "NA";
            }
            customers.push({ id: doc.id, data: addData });

          });
          //return markers;
          res(customers);
        })
      })
    })
  }

  public UpdatePatientData(currentFacilityId, patientid, data) {
    let path = 'SQACustomers/' + currentFacilityId + '/patients/';
    this.updateCollectionData(path, patientid, data);

  }

  public async DeleteFacilityWithUsers(currentFacilityId) {
    const users = await this.getUsersForFacility(currentFacilityId);
    console.log('facility users ', users);
    await users.forEach(async user => {
      await this.deleteSQAUser(user.id);
    });
    let url = this.apiEP + "/deleteFacilityUserWithAuth?id=" + currentFacilityId.toString();
    return new Promise((res, rej) => {
      firebase.auth().currentUser.getIdToken().then(async token => {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });

        this._http.get(url, { headers: headers }).toPromise().then(user => {
          res(user);
        }).catch(err => {
          rej(err);
        });

      });
    })
  }

  public DeletePatient(currentFacilityId, patientid) {
    return new Promise<any>((res, rej) => {

      const statsRef = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc('stats');
      const increment = firebase.firestore.FieldValue.increment(-1);
      const batch = this.db.batch();
      const reqRef = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patientid.toString());
      batch.delete(reqRef);
      batch.set(statsRef, { patientCount: increment }, { merge: true });
      batch.commit().then(val => {
        console.log('batch res ', val);
        res(val);
      }).catch(err => {
        rej(err);
      });
    });
  }

  public DeleteTest(currentFacilityId, patientid, testid) {
    return new Promise<any>((res, rej) => {

      const statsRef = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc('stats');
      const statsRef2 = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patientid.toString()).collection('tests').doc('stats');
      const increment = firebase.firestore.FieldValue.increment(-1);
      const batch = this.db.batch();
      const reqRef = this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patientid.toString()).collection('tests').doc(testid.toString());
      batch.delete(reqRef);
      batch.set(statsRef, { testCount: increment }, { merge: true });
      batch.set(statsRef2, { testCount: increment }, { merge: true });
      batch.commit().then(val => {
        console.log('batch res ', val);
        res(val);
      }).catch(err => {
        rej(err);
      });
    });
  }


  UploadProfilePicture(currentFacilityId, file: File) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`${currentFacilityId}/profileimg.jpg`);

    return new Promise<any>((res, rej) => {
      ref.put(file).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          res(url);
        }).catch(err => {
          rej(err);
        });
      });
    });

  }

  public getDevicedata(deviceID) {
    return this.getCollectionData("Devices", deviceID)
  }

  public updateDevicedata(deviceID, data) {
    this.updateCollectionData('Devices', deviceID, data);
  }


  // =================
  // Filtercalibration
  // =================
  public addFilterCalibration(data) {
    this.addCollectionItam('FilterCalibrations', data);
  }

  public addDevicetoFilterCalibration(calibrationid, deviceid: string, data) {
    this.updateCollectionData('FilterCalibrations/' + calibrationid + '/Devices/', deviceid, data);
  }

  public addMeasurementtoFilterCalibration(calibrationid, deviceid: string, data) {
    let path = 'FilterCalibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    return this.addCollectionItam(path, data);

  }

  // Two level functions that currently cannot be replaced with simple global functions
  public getFilterCalibrationmeasurements(calibrationid) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllCalibrationMeasurements?id=" + calibrationid;
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collection('FilterCalibrations').doc(calibrationid).collection('Devices').get()
        .then(async querySnapshot => {

          for (const doc of querySnapshot.docs) {

            await this.db.collection('FilterCalibrations').doc(calibrationid).collection('Devices').doc(doc.id).collection('measurements').get()
              .then(async querySnapshot2 => {

                querySnapshot2.forEach(measuredoc => {
                  markers.push({ Calibrationid: calibrationid, Deviceid: doc.id, measurementid: measuredoc.id, data: measuredoc.data() });
                });

              }).catch(error => {
                console.log("Error getting sub-collection documents", error);
                rej(error);
              })
          }
          res(await markers);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          rej(error);
        });
    });

  }


  public DeleteFilterMeasurement(calibrationid, deviceid, measurementid) {
    let path = 'FilterCalibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.deleteCollectionItem(path, measurementid);
  }

  public getFilterCalibrations() {
    return this.getCollectionList("FilterCalibrations");
  }


  public getDevicesinFilterCalibration(calibrationid) {
    return this.getCollectionList("FilterCalibrations/" + calibrationid + "/Devices")
  }

  public UpdateFilterCalibrations(calibrationid, data) {
    this.updateCollectionData('FilterCalibrations', calibrationid, data);
  }






  // ==================================  end Fiilter calibration


  // ===================
  // Calibration section
  // ===================
  public addCalibration(data) {
    this.addCollectionItam('Calibrations', data);
  }

  public addClinicalAnalysis(data) {
    this.addCollectionItam('ClinicalAnalysis', data);
  }


  public addDevicetoCalibration(calibrationid, deviceid: string, data) {
    this.updateCollectionData('Calibrations/' + calibrationid + '/Devices/', deviceid, data);
  }


  public addMeasurementtoCalibration(calibrationid, deviceid: string, data) {
    let path = 'Calibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.addCollectionItam(path, data);
  }

  public addMeasurementtoClinicalAnalysis(clinicalanalysisidid, deviceid: string, data) {
    let path = 'ClinicalAnalysis/' + clinicalanalysisidid + '/Devices/' + deviceid + '/measurements';
    this.addCollectionItam(path, data);
  }


  // Two level functions that currently cannot be replaced with simple global functions
  public getCalibrationmeasurements(calibrationid, collection: string = "Calibrations") {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllCalibrationMeasurements?id=" + calibrationid;
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collection(collection).doc(calibrationid).collection('Devices').get()
        .then(async querySnapshot => {

          for (const doc of querySnapshot.docs) {

            await this.db.collection(collection).doc(calibrationid).collection('Devices').doc(doc.id).collection('measurements').get()
              .then(async querySnapshot2 => {

                querySnapshot2.forEach(measuredoc => {
                  markers.push({ Calibrationid: calibrationid, Deviceid: doc.id, measurementid: measuredoc.id, data: measuredoc.data() });
                });

              }).catch(error => {
                console.log("Error getting sub-collection documents", error);
                rej(error);
              })
          }
          res(await markers);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          rej(error);
        });
    });

  }


  public DeleteMeasurement(calibrationid, deviceid, measurementid) {
    let path = 'Calibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.deleteCollectionItem(path, measurementid);
  }

  public getCalibrations() {
    return this.getCollectionList("Calibrations");
  }

  public getClinicalAnalysises() {
    return this.getCollectionList("ClinicalAnalysis");
  }

  public getDevicesinClinicalAnalysis(clinicalanalysisid) {
    return this.getCollectionList("ClinicalAnalysis/" + clinicalanalysisid + "/Devices")
  }

  public getDevicesinCalibration(calibrationid) {
    return this.getCollectionList("Calibrations/" + calibrationid + "/Devices")
  }

  public UpdateCalibrations(calibrationid, data) {
    this.updateCollectionData('Calibrations', calibrationid, data);
  }

  // ===================
  // Control Calibration
  // ===================
  public addControlCalibration(data) {
    this.addCollectionItam('ControlCalibrations', data);
  }

  public addDevicetoControlCalibration(calibrationid, deviceid: string, data) {
    this.updateCollectionData('ControlCalibrations/' + calibrationid + '/Devices/', deviceid, data);
  }

  public addMeasurementtoControlCalibration(calibrationid, deviceid: string, data) {
    let path = 'ControlCalibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.addCollectionItam(path, data);
  }

  public getControlCalibrations() {
    return this.getCollectionList("ControlCalibrations");
  }


  public DeleteControlMeasurement(calibrationid, deviceid, measurementid) {
    let path = 'ControlCalibrations/' + calibrationid + '/Devices/' + deviceid + '/measurements';
    this.deleteCollectionItem(path, measurementid);
  }


  public getDevicesinControlCalibration(calibrationid) {
    return this.getCollectionList("ControlCalibrations/" + calibrationid + "/Devices")
  }

  public UpdateControlCalibrations(calibrationid, data) {
    this.updateCollectionData('ControlCalibrations', calibrationid, data);
  }


  public getControlCalibrationmeasurements(calibrationid) {
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getAllCalibrationMeasurements?id=" + calibrationid;
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collection('ControlCalibrations').doc(calibrationid).collection('Devices').get()
        .then(async querySnapshot => {

          for (const doc of querySnapshot.docs) {

            await this.db.collection('ControlCalibrations').doc(calibrationid).collection('Devices').doc(doc.id).collection('measurements').get()
              .then(async querySnapshot2 => {

                querySnapshot2.forEach(measuredoc => {
                  markers.push({ Calibrationid: calibrationid, Deviceid: doc.id, measurementid: measuredoc.id, data: measuredoc.data() });
                });

              }).catch(error => {
                console.log("Error getting sub-collection documents", error);
                rej(error);
              })
          }
          res(await markers);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          rej(error);
        });
    });

  }



  // ==============================================  Calibration


  public addNewRequest(data: Request) {
    // return this.addCollectionItam("Requests", data);
    // let url = "https://us-central1-messqasystem.cloudfunctions.net/addNewRequest";
    // return this._http.post(url, data);

    return new Promise<any>((res, rej) => {
      const statsRef = this.db.collection('Requests').doc('stats');
      const currentFacilityId = data.facilityId;
      const customerRef = this.db.collection('SQACustomers').doc(currentFacilityId);
      const increment = firebase.firestore.FieldValue.increment(1);
      const batch = this.db.batch();
      const reqRef = this.db.collection('Requests').doc();
      batch.set(reqRef, data);
      batch.set(statsRef, { requestsCount: increment }, { merge: true });
      batch.set(customerRef, { requestCount: increment }, { merge: true });
      batch.commit().then(val => {
        console.log('batch res ', val);
        res(val);
      }).catch(err => {
        rej(err);
      });
    })

  }

  public getAllRequests() {
    return this.getCollectionList("Requests");
    // let url = 'https://us-central1-messqasystem.cloudfunctions.net/getRequests';
    // return this._http.get(url);

  }

  // not in use
  public getRequestsForFacility(id) {

    return new Promise<any>((res, rej) => {
      const markers: any = [];
      this.db.collection('Requests').where("facilityId", "==", id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // if (doc.data().facilityId === id) {
            markers.push({ id: doc.id, data: doc.data() });
            // }
          });

          res(markers);
        });
    });

    // let url = "https://us-central1-messqasystem.cloudfunctions.net/getRequestsforSelectedFacility?id=" + id;
    // return this._http.get(url);
  }

  public updateRequest(id, data) {
    return this.updateCollectionData('Requests', id, data);
    // const updateRequestURL = 'https://us-central1-messqasystem.cloudfunctions.net/updateRequest?id=' + id;
    // return this._http.post(updateRequestURL, data);
  }

  public deleteRequest(id) {
    return this.deleteCollectionItem('Requests', id);
    // const url = "https://us-central1-messqasystem.cloudfunctions.net/deleteRequest?id=" + id;
    // return this._http.get(url);
  }

  public updateTestCreditItem(creditId, creditData) {

    return new Promise<any>((res, rej) => {
      this.afs.collection('Credits').doc(creditId).get()
        .subscribe(doc => {
          if (doc.data().status === 'unused') {
            let requStatus = creditData['status'];

            this.afs.collection('Credits').doc(creditId).set(creditData, { merge: true })
              .then((docRef) => {
                res({ id: creditId, data: creditData });
              })
              .catch((error) => {
                console.log("Error getting documents: ", error);
                rej(error);
              });
          } else {
            rej('Cannot reset status!');
          }
        })
    });

    // const updateCreditURL = 'https://us-central1-messqasystem.cloudfunctions.net/updateCreditItem?id=' + creditId;
    // return this._http.post(updateCreditURL, data).toPromise();
  }

  public createTestCreditBatch(amount: number, creditCount: number, creditType: string = 'Development',devicetype:string = 'SQA-iO') {

    return new Promise<any>((res, rej) => {

      let admin = {
        adminEmail: this.currentAdmin.email,
        adminId: this.currentAdmin.uid
      };
      let currentBatchId = formatDate(new Date(), 'yyyy-MM-dd_HH:mm:ss', 'en'); // new Date().toISOString();
      let batchArr: any = [];
      for (let index = 0; index < amount; index++) {
        let item: TestCredit = {
          status: 'unused',
          credit: creditCount,
          creator: admin,
          creationDate: new Date(),
          type: creditType,
          batchId: currentBatchId,
          printLock: false,
          creditDeviceType:devicetype
        };

        this.updateCollectionData("Credits", this.generateRandomId("Credits"),item).then(async doc => {
          batchArr.push({ 'id': doc.id, 'data': item });
        }).catch(err => {
          console.log('batch err', err);
          rej(err);
        });

      }

      let itemData: CreditBatch = {
        creator: admin,
        type: creditType,
        creditNumber: creditCount,
        creditAmount: amount,
        printLock: false,
        creditDeviceType:devicetype
      }
      this.afs.collection('CreditBatch').doc(currentBatchId).set(itemData)
        .then((docRef) => {
          console.log('created batch document');
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });

      res(batchArr);
    });
  }

  public getListOfTestCreditBatches() {
    return this.getCollectionList('CreditBatch');
  }

  public getCreditItemsOfBatch(batchId: string) {
    return new Promise<any>((res, rej) => {
      const markers: any = [];
      this.db.collection('Credits').where("batchId", "==", batchId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // if (doc.data().facilityId === id) {
            markers.push({ id: doc.id, data: doc.data() });
            // }
          });

          res(markers);
        })
        .catch(err => {
          rej(err);
        });
    });
  }

  public deleteTestCreditBatch(id) {

    return new Promise<any>((res, rej) => {
      this.deleteCollectionItem('CreditBatch', id)
        .then(batchItem => {
          this.db.collection('Credits').where("batchId", "==", id).get()
            .then(snapShot => {
              snapShot.forEach(doc => {
                this.deleteCollectionItem('Credits', doc.id);
              })
              res(batchItem);
            })
            .catch(err => {
              rej(err);
            })
        })
        .catch(err => {
          rej(err);
        });

    });
  }

  public updateTestCreditBatch(id, data) {
    return this.updateCollectionData('CreditBatch', id, data);
  }

  generateRandomId(collectionName: string) {
    const restrictedCharecters = ['o', 'l', 'i', '0']
    const docRef = this.db.collection(collectionName).doc()
    const id: string = docRef.id
    if (restrictedCharecters.some(function (v) { return id.substring(0,9).toLowerCase().indexOf(v) >= 0; })) {
      return this.generateRandomId(collectionName)
    }
    else{
      return id
    }
  }

  public addNewTestCreditItem(amount: number = 50, creditType: string = "Production",devicetype:string ='SQA-iO') {
    let admin = {
      adminEmail: this.currentAdmin.email,
      adminId: this.currentAdmin.uid
    };
    let item: TestCredit = {
      status: 'unused',
      credit: amount,
      creator: admin,
      creationDate: new Date(),
      type: creditType,
      creditDeviceType:devicetype,
    };

    return new Promise(async (res, rej) => {
      const creditId:string = this.generateRandomId("Credits")
      console.log(creditId,"Call..............");
      
      try {
      const doc = await this.updateCollectionData("Credits",creditId,{...item,...{creditId:creditId}})
      res({ "id": doc.id, "data": item });
      } catch (error) {
        rej(error)
      }
      // this.afs.collection("Credits").add(item)
      //   .then((doc) => {
      //     console.log({ "id": doc.id, "data": item });
      //     res({ "id": doc.id, "data": item });
      //   })
      //   .catch((error) => {
      //     console.error("Error adding document: ", error);
      //     rej(error);
      //   });
    })

    // return this.addCollectionItam("Credits", item);
    // const url = "https://us-central1-messqasystem.cloudfunctions.net/addNewCreditItem";
    // return this._http.post(url, JSON.stringify(item));
  }

  public deleteTestCreditItem(id: string) {
    return new Promise<any>((res, rej) => {
      this.db.collection('Credits').doc(id).get()
        .then(snapshot => {
          console.log(snapshot.data());
          let batchId = snapshot.data()["batchId"];
          if (batchId !== undefined) {
            console.log('part of batch ', batchId);
            const decrement = firebase.firestore.FieldValue.increment(-1);
            this.db.collection('CreditBatch').doc(batchId).set({ creditAmount: decrement }, { merge: true })
              .then(batch => {
                this.deleteCollectionItem('Credits', id).then(creditItem => {
                  res(creditItem);
                }).catch(err => {
                  rej(err);
                });
              }).catch(err => {
                rej(err);
              });
          } else {
            this.deleteCollectionItem('Credits', id).then(creditItem => {
              res(creditItem);
            }).catch(err => {
              rej(err);
            });
          }
        });
    });

    // const url = "https://us-central1-messqasystem.cloudfunctions.net/deleteCreditItem?id=" + id;
    // return this._http.get(url);
  }

  public getAllTestCredits() {
    return this.getCollectionList("Credits");

    // const url = "https://us-central1-messqasystem.cloudfunctions.net/getAllCreditItems";
    // return this._http.get(url);
  }

  getPaginatedData(pageSize, collectionpath, cursor?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
    let query = cursor != undefined ? this.db.collection(collectionpath).orderBy("creationDate",'desc').startAfter(cursor).limit(pageSize) : this.db.collection(collectionpath).orderBy("creationDate",'desc').limit(pageSize)

    return new Promise((res, rej) => {
      query.get().then((snapshot) => {
        res(snapshot)
      })
    });
  }

  getFilteredTest(search) {
    let url = "https://ioanalyticservices-ldoxyjbqhq-uc.a.run.app/getFilteredCredits?";

    return this._http.post(url, search);
  }

  public getAllTestCreditsByFilter(status: string, typeArr, creatorIsMe: boolean) {
    return new Promise<any>((res, rej) => {
      let markers = [];
      if (creatorIsMe) {
        this.db.collection('Credits')
          .where('creator.adminId', '==', this.currentAdmin.uid)
          .where('status', '==', status)
          .where('type', 'in', typeArr)
          .get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              // if (doc.data().facilityId === id) {
              markers.push({ id: doc.id, data: doc.data() });
              // }
            });

            res(markers);
          }).catch(err => {
            rej(err);
          })
      } else {
        this.db.collection('Credits')
          .where('status', '==', status)
          .where('type', 'in', typeArr)
          .get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              // if (doc.data().facilityId === id) {
              markers.push({ id: doc.id, data: doc.data() });
              // }
            });
            res(markers);
          }).catch(err => {
            rej(err);
          })
      }
    })
  }

  public redeemTestCredits(creditId, facilityID, facilityNAME) {

    return new Promise<any>(res => {
      let currentFacilityCredits: number;
      let creditsToAdd: number = 0;

      this.getTestCreditItem(creditId).then((creditItem) => {
        console.log('creditItem', creditItem);
        if (creditItem.toString().includes('Error')) {
          res(creditItem);
        } else {
          creditsToAdd = Number(creditItem['data']['credit']);
          console.log('creditsToAdd', creditsToAdd);
          return this.getFacilityCurrentCredits(facilityID);
        }
      })
        .then((facilityCredits) => {
          console.log('facilityCredits', facilityCredits);
          if (facilityCredits.toString().includes('Error')) {
            res(facilityCredits);
          } else {
            currentFacilityCredits = Number(facilityCredits['testCredits']);
            let newCreditNumber: number = currentFacilityCredits + creditsToAdd;
            console.log('new credit number ', newCreditNumber);
            let data = {
              testCredits: newCreditNumber
            };
            console.log('facility to add data', data);
            return this.updateFacilityCredits(facilityID, data);
          }
        })
        .then((update) => {
          console.log('update', update);
          let facility = {
            facilityId: facilityID,
            facilityName: facilityNAME
          };
          let admin = {
            email: this.currentAdmin.email,
            id: this.currentAdmin.uid,
            userType: 'admin'
          };
          let data = { status: 'used', usingFacility: facility, redeemingDate: new Date(), redeemedBy: admin };
          console.log('data', data);

          return this.updateTestCreditItem(creditId, data);
        })
        .then((status) => {
          console.log(status);
          res('test credits updated successfully!');
        })
        .catch((err) => {
          console.log('redeem credits err', err);
          res(err);
        });
    });
  }

  private getTestCreditItem(creditId) {
    return new Promise<any>((res, rej) => {
      this.afs.collection('Credits').doc(creditId).get()
        .subscribe((docRef) => {
          if (docRef.exists) {
            let credit = docRef.data();
            if (credit !== undefined) {
              let status = credit['status'];
              if (status === 'unused') {
                res({ id: creditId, data: docRef.data() });
              } else {
                res('Error: Credit has already been used!');
              }
            }

          }
          else {
            res('Error: No such document!');
            console.log("No such document!");
          }
        });
    });

    // const creditItemURL = 'https://us-central1-messqasystem.cloudfunctions.net/redeemCredit?id=' + creditId;
    // return this._http.get(creditItemURL).toPromise();
  }

  // =============================================================================================================

  public createRegistrationNumberBatch(amount: number, registrationType: string = 'Development',sofwareType:boolean=false) {

    return new Promise<any>(async (res, rej) => {

      let admin = {
        adminEmail: this.currentAdmin.email,
        adminId: this.currentAdmin.uid
      };
      let currentBatchId = formatDate(new Date(), 'yyyy-MM-dd_HH:mm:ss', 'en'); // new Date().toISOString();
      let batchArr: any = [];
      for (let index = 0; index < amount; index++) {
        let item: RegistrationNumber = {
          status: 'unused',
          creator: admin,
          creationDate: new Date(),
          type: registrationType,
          iOwMode:sofwareType,
          batchId: currentBatchId
        };

        await this.addCollectionItam("RegistrationNumbers", item).then(async doc => {
          batchArr.push({ 'id': doc.id, 'data': item });
        }).catch(err => {
          console.log('batch err', err);
          rej(err);
        });

      }

      let itemData: RegisterNumberBatch = {
        creator: admin,
        type: registrationType,
        registrationAmount: amount,
        printLock: false,
        iOwMode:sofwareType 
      }

      this.afs.collection('RegisterNumberBatchs').doc(currentBatchId).set(itemData)
        .then((docRef) => {
          console.log('created batch document');
          res({ batchId: currentBatchId, batches: batchArr, email: this.currentAdmin.email,});
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }










  // ===============================================================================================================

  private getFacilityCurrentCredits(facilityid) {

    return new Promise<any>((res, rej) => {
      this.afs.collection('SQACustomers').doc(facilityid).get()
        .subscribe((doc) => {
          if (doc.exists) {
            let data = doc.data();
            if (data !== undefined) {
              res({ id: facilityid, testCredits: data['testCredits'] });
            }
            else {
              res("Error: Empty document!");
            }
          }
          else {
            res("Error: No such document!");
            console.log("No such document!");
          }
        });
    });

    // const facilityCreditURL = 'https://us-central1-messqasystem.cloudfunctions.net/getCredit?id=' + facilityid;
    // return this._http.get(facilityCreditURL).toPromise();
  }

  private updateFacilityCredits(facilityid, data) {
    return this.updateCollectionData('SQACustomers', facilityid, data);
    // const updateFacilityURL = 'https://us-central1-messqasystem.cloudfunctions.net/UpdateFacilitySettings?id=' + facilityid;
    // return this._http.post(updateFacilityURL, data).toPromise();
  }

  public getTotalCustomerNumbers() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalCustomerNumbers';
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      this.afs.collection('SQACustomers').get()
        .subscribe(async function (querySnapshot) {
          let totalCustomers = querySnapshot.docs.length;
          let patientscounter = 0;
          let testscounter = 0;
          await querySnapshot.forEach(facility => {
            if (facility.data().testCount !== undefined && facility.data().patientCount !== undefined) {
              testscounter += facility.data().testCount;
              patientscounter += facility.data().patientCount;
            }
          });
          console.log('count ', testscounter, patientscounter);
          res(await { customers: totalCustomers, patients: patientscounter, tests: testscounter });
        });
    });
  }




  public getTotalRequestsNumber() {
    return this.getTotalNumber('Requests');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=Requests';
    // return this._http.get(url);
  }

  public getTotalCalibrationsNumber() {
    return this.getTotalNumber('Calibrations');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=Calibrations';
    // return this._http.get(url);
  }

  public getTotalDevicesNumber() {
    return this.getTotalNumber('Devices');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=Devices';
    // return this._http.get(url);
  }

  public getTotalAdminsNumber() {
    return this.getTotalNumber('MESUsers');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=MESUsers';
    // return this._http.get(url);
  }

  public getTotalCreditsNumber() {
    return this.getTotalNumber('Credits');
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalNumbers?collection=Credits';
    // return this._http.get(url);
  }

  public getTotalTests() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalTests';
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collectionGroup('tests').get()
        .then(async tests => {
          console.log('total test count ', tests.size);
          for (const test of tests.docs) {
            let path = test.ref.path.split('/');
            let facility = path[1];
            let patient = path[3];
            if (test.id !== 'stats') {
              await markers.push({ facilityid: facility, patientid: patient, testid: test.id, data: test.data() });
            }
          }
          res(await markers);
          return;
        }).catch(err => {
          rej(err);
        });
    });
  }

  public getTotalPatients() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalPatients';
    // return this._http.get(url);
    return new Promise<any>((res, rej) => {

      this.db.collectionGroup('patients').get()
        .then(async patients => {
          let markers: any = [];
          for (const patient of patients.docs) {
            let path = patient.ref.path.split('/');
            let facility = path[1];
            // let testCnt = 0; //patient.ref.collection('tests')
            markers.push({ facilityid: facility, patientid: patient.id, data: patient.data() });
          }
          console.log('data patients ', markers);
          res(await markers);
          return;
        }).catch(err => {
          rej(err);
        });
    });
  }

  public addNewAdmin(data) {

    const url = this.apiEP + '/addNewAdminUserWithAuth';

    return new Promise<any>((ress, rejj) => {
      firebase.auth().currentUser.getIdToken().then(token => {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        // const myUID = '';
        // const notMyUID = '';

        // const body = { uid: myUID };
        this._http.post(url, data, { headers: headers }).toPromise().then((res) => {
          ress(res);
        }).catch(err => {
          if (err.error.code === 'auth/email-already-exists') {
            console.log('err adding admin  http ', err);
            rejj(new EmailNotAvailableError());
          } else {
            rejj(err);
          }
        });
      });
    });

  }

  public changeFacilityPassword(id: string, newPassword) {
    const data = JSON.stringify({ password: newPassword })
    const url = this.apiEP + '/changeFacilityPasswordWithAuth?id=' + id;
    return new Promise<any>((ress, rejj) => {
      firebase.auth().currentUser.getIdToken().then(token => {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });

        return this._http.post(url, data, { headers: headers }).toPromise().then((res) => {
          ress(res);
        }).catch(err => {
          rejj(err);
        });
      });
    });
  }

  public updateAdmin(id: string, data) {
    const url = this.apiEP + '/updateAdminUserWithAuth?id=' + id;
    return new Promise<any>((ress, rejj) => {
      firebase.auth().currentUser.getIdToken().then(token => {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });

        return this._http.post(url, data, { headers: headers }).toPromise().then((res) => {
          ress(res);
        }).catch(err => {
          rejj(err);
        });
      });
    });
  }

  public deleteAdmin(id: string) {
    const url = this.apiEP + '/deleteAdminUserWithAuth?id=' + id;
    return new Promise<any>((ress, rejj) => {
      firebase.auth().currentUser.getIdToken().then(token => {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });

        return this._http.get(url, { headers: headers }).toPromise().then((res) => {
          ress(res);
        }).catch(err => {
          rejj(err);
        });
      });
    });
  }

  public getAllAdmins() {
    return this.getCollectionList('MESUsers');
  }

  public getAdmin(id: string) {
    return this.getCollectionData('MESUsers', id);
  }

  public getAllAdminsWithInfo() {
    const url = this.apiEP + '/getAllAdminsWithInfoWithAuth';
    return new Promise<any>((ress, rejj) => {
      firebase.auth().currentUser.getIdToken().then(token => {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        // const myUID = '';
        // const notMyUID = '';

        // const body = { uid: myUID };
        this._http.get(url, { headers: headers }).toPromise().then((res) => {
          ress(res);
        }).catch(err => {
          rejj(err);
        });
      });
    });
  }

  public getAllAverageTestsPerDay() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getAvrgTestsTotal';
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {

      const allDates: any = [];
      let distinctDates: any = [];
      const avrgTests: any = [];

      this.db.collectionGroup('tests').get().then(async tests => {

        for (const test of tests.docs) {
          try {
            let timestamp = test.data().dateTime.toDate();
            let element = formatDate(new Date(timestamp), 'yyyy/MM/dd', 'en')
            allDates.push(element);
          } catch (error) {
            console.log('avrg test date error');
          }
        }

        const distinct = (value: any, index: any, self: any) => {
          return self.indexOf(value) === index;
        };
        distinctDates = allDates.filter(distinct);
        distinctDates.sort();
        console.log(distinctDates);

        let days = 1;
        let test_count = 0;
        for (const date of distinctDates) {
          let daily_test_count = 0;
          for (const testDate of allDates) {
            if (testDate === date) {
              test_count++;
              daily_test_count++;
            }
          }
          let avrg = test_count / days;

          avrgTests.push({ Date: date, DailyTests: daily_test_count, TotalTests: test_count, Days: days, Avrg: avrg });
          days++;
        }
        res(await avrgTests);
      }).catch(err => {
        console.log("Error getting documents: ", err);
        rej(err);
      });
    });

  }

  public addDeviceServiceData(deviceID, data) {
    // let path = 'Devices/' + deviceID.toString() + '/ServiceData';
    // return this.addCollectionItam(path, data);

    return new Promise<any>((res, rej) => {
      const statsRef = this.db.collection('Devices').doc(deviceID);

      const increment = firebase.firestore.FieldValue.increment(1);
      const batch = this.db.batch();
      const reqRef = this.db.collection('Devices').doc(deviceID).collection('servicedata').doc();
      batch.set(reqRef, data);
      batch.set(statsRef, { selfTestCount: increment }, { merge: true });
      batch.commit().then(val => {
        console.log('batch res ', val);
        res(val);
      }).catch(err => {
        rej(err);
      });
    })
  }



  public checkregnum(regnum: string) {

    let url = "https://smsapi-ldoxyjbqhq-uc.a.run.app"

    this._http.get(url).subscribe(res => {
      console.log(res);

      return res;

    });




  }



  // public addAdminGeoToFacilities() {
  //   this.db.collection('SQACustomers').get().then(snapshot => {
  //     for (const doc of snapshot.docs) {
  //       this.db.collection('SQACustomers').doc(doc.id).get().then(data => {
  //         let city = data.data().city;
  //         let country = data.data().country;
  //         let newInfo: SQACustomer = {
  //           geoCity: city,
  //           geoCountry: country
  //         };
  //         this.UpdateFacilityProfile(doc.id, newInfo);
  //       });
  //     }
  //   })
  // }

  // public addDatatotestsForFacility(currentFacilityId, currentFacilityName) {
  //   // let url = "https://us-central1-messqasystem.cloudfunctions.net/getNestedCollection?id=" + this.currentFacilityId + "&maincollection=patients&subcollection=tests";
  //   // return this._http.get(url);

  //   return new Promise<any>((res, rej) => {

  //     this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').get()
  //       .then(async patientsSnapshot => {
  //         let markers: any = [];
  //         let noOfPatients = patientsSnapshot.size;
  //         let fTestCount = 0;

  //         for (const patient of patientsSnapshot.docs) {
  //           await this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patient.id).collection('tests').get()
  //             .then(async testSnapshot => {
  //               let pTestCount = testSnapshot.size;
  //               fTestCount = fTestCount + pTestCount;
  //               await this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patient.id)
  //               .set({ testCount: pTestCount }, { merge: true }).then(pTCnt => {
  //                 console.log('pTestCount ', pTestCount);
  //               });
  //               for (const test of testSnapshot.docs) {
  //                 if (test.id !== 'stats') {
  //                   let itemData = {
  //                     facilityId: currentFacilityId,
  //                     facilityName: currentFacilityName,
  //                     patientId: patient.id,
  //                     patientFirstName: patient.data().firstName,
  //                     patientLastName: patient.data().lastName
  //                   }
  //                   await this.db.collection('SQACustomers').doc(currentFacilityId).collection('patients').doc(patient.id).collection('tests').doc(test.id)
  //                   .set(itemData, { merge: true }).then(snap => {
  //                     console.log('updated data ', snap);
  //                   });

  //                 }
  //               }
  //             }).catch(error => {
  //               console.log("Error getting sub-collection documents", error);
  //               rej(error);
  //             })
  //         }
  //         await this.db.collection('SQACustomers').doc(currentFacilityId)
  //         .set({patientCount: noOfPatients, testCount: fTestCount}, {merge: true })
  //         .then(pCount => {
  //           console.log('pCount ', noOfPatients);
  //           console.log('tCount ', fTestCount);

  //         });
  //         res(await markers);
  //         console.log(await 'success')
  //       })
  //       .catch(error => {
  //         console.log("Error getting documents: ", error);
  //         rej(error);
  //       });
  //   });
  // }

  public updateFacilityLocation(facilityId, latitude, longitude) {
    return new Promise<any>((res, rej) => {
      const location = {
        lat: latitude,
        lng: longitude
      }
      this.afs.doc(`SQACustomers/${facilityId}`).set({ geoLocation: location }, { merge: true }).then(userlocation => {
        res(location);
      })
        .catch(err => {
          console.log('loc saving err ', err);
          rej(err);
          // rej(err);
        });
    });

  }

  public updateDeviceLocation(deviceSN, latitude, longitude) {
    return new Promise<any>((res, rej) => {
      const deviceData: Device = {};
      const location = {
        lat: latitude,
        lng: longitude
      };
      deviceData.usingFacility = {
        facilityAddress: {
          geo: location
        }
      };
      this.afs.doc(`Devices/${deviceSN}`).set(deviceData, { merge: true }).then(userlocation => {
        res(location);
      })
        .catch(err => {
          console.log('loc saving err ', err);
          rej(err);
          // rej(err);
        });
    });

  }

  public getFacilityTotalCredits(facilityId) {
    return new Promise<any>((res, rej) => {

      this.db.collection(`Credits`).where('usingFacility.facilityId', '==', facilityId).get().then(async dox => {
        let credits = 0;
        for (const doc of dox.docs) {
          credits = credits + doc.data().credit;
          console.log("all credits ", credits);
          console.log('ids ', doc.id);

        };
        res(await credits);
      })
        .catch(err => {
          console.log('loc saving err ', err);
          rej(err);
          // rej(err);
        });
    });
  }

  public AddNotification(data) {
    return this.addCollectionItam('Notifications', data);
  }

  public DeleteNotification(notificationId) {
    return this.deleteCollectionItem('Notifications', notificationId);
  }

  public UpdateNotification(notificationId, data) {
    return this.updateCollectionData('Notifications', notificationId, data);
  }

  public getAllNotifications() {
    return this.getCollectionList('Notifications');
  }

  public AddDistributer(data) {
    return this.addCollectionItam('Distributers', data);
    // return new Promise<any>((res, rej) => {
    //   this.db.collection('Distributers').doc(code).set(data)
    //   .then((doc) => {
    //     console.log({ "id": code, "data": data });
    //     res(code);
    //   })
    //   .catch((error) => {
    //     console.error("Error adding document: ", error);
    //     rej(error);
    //   });
    // });
  }

  public DeleteDistributer(code) {
    return this.deleteCollectionItem('Distributers', code);
  }

  public UpdateDistributer(code, data) {
    return this.updateCollectionData('Distributers', code, data);
  }

  public getDistributerById(id: string) {
    return this.getCollectionData('Distributers', id);
  }

  public getAllDistributers() {
    return this.getCollectionList('Distributers');
  }

  public getAllDistributerCount() {
    return new Promise<any>((res, rej) => {
      this.db.collection('Distributers').get().then(snap => {
        res(snap.size);
      }).catch(e => {
        res(0);
      })
    })

  }

  public getMESDistributers() {
    return this.getCollectionList('MESDistributers');
  }

  public addQCBatch(data: ControlsBatch) {
    let dData: ControlsBatch = {
      ...data,
      adminEditor: {
        id: this.currentAdmin.uid,
        email: this.currentAdmin.email,
        name: this.currentAdmin.name,
        dateTime: new Date()
      }
    }
    return new Promise<any>((res, rej) => {

      this.db.collection('Controls').doc(data.batchID).set(dData)
        .then((doc) => {
          console.log({ "id": data.batchID, "data": dData });
          res(dData);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public DeleteQCBatch(batchId) {
    return this.deleteCollectionItem('Controls', batchId);
  }

  public UpdateQCBatch(batchId, data: ControlsBatch) {
    let dData: ControlsBatch = {
      ...data,
      adminEditor: {
        id: this.currentAdmin.uid,
        email: this.currentAdmin.email,
        name: this.currentAdmin.name,
        dateTime: new Date()
      }
    }
    return this.updateCollectionData('Controls', batchId, dData);
  }

  public getAllQCControls() {
    return this.getCollectionList('Controls');
  }

  public getUsersForFacility(facilityId) {
    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db.collection('SQAUsers').where("facilityID", "==", facilityId).get().then(async users => {
        if (users.empty) {
          console.log('No matching documents.');
          // rej('No tests found.');
        }
        for (const user of users.docs) {
          await markers.push({ id: user.id, data: user.data() });
        }
        res(await markers);
      }).catch(e => {
        rej(e);
      });

    });

  }

  public getTotalQCTests() {
    // const url = 'https://us-central1-messqasystem.cloudfunctions.net/getTotalTests';
    // return this._http.get(url);

    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db.collectionGroup('controlTests').get()
        .then(async tests => {
          console.log('total qc test count ', tests.size);
          for (const test of tests.docs) {
            let path = test.ref.path.split('/');
            let facility = path[1];
            if (test.id !== 'stats') {
              await markers.push({ facilityid: facility, testid: test.id, data: test.data() });
            }
          }
          res(await markers);
          return;
        }).catch(err => {
          rej(err);
        });
    });
  }

  public getAllUsers() {
    return this.getCollectionList('SQAUsers');
  }

  public deleteSQAUser(id: string) {
    const url = this.apiEP + '/deleteSQAUserAdminWithAuth?id=' + id;

    return new Promise((res, rej) => {
      firebase.auth().currentUser.getIdToken().then(async token => {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });

        this._http.get(url, { headers: headers }).toPromise().then(user => {
          res(user);
        }).catch(err => {
          rej(err);
        });

      });
    })
  }
  public getProficiencyBatch(id) {
    return this.getCollectionData('Proficiency', id);
  }

  public getAllProficiencyBatches(scheme: string) {
    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db.collection('Proficiency').where('scheme', '==', scheme).get()
        .then(async proficiencyBatches => {
          if (proficiencyBatches.empty) {
            // console.log('No active notifications.');
            rej('No data found.');
          }
          for (const batch of proficiencyBatches.docs) {
            await markers.push({ id: batch.id, data: batch.data() });
          }
          res(await markers);
        }).catch(err => {
          rej(err);
        })

    });
    // return this.getCollectionList('Proficiency');
  }

  public addProficiencyBatch(data: ProficiencyBatch) {
    let dData: ProficiencyBatch = {
      ...data,
      adminEditor: {
        id: this.currentAdmin.uid,
        email: this.currentAdmin.email,
        name: this.currentAdmin.name,
        dateTime: new Date()
      }
    }
    return new Promise<any>((res, rej) => {

      this.db.collection('Proficiency').doc(data.distributionNo).set(dData)
        .then((doc) => {
          console.log({ "id": data.distributionNo, "data": dData });
          res(dData);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public DeleteProficiencyBatch(distributionNo) {
    return this.deleteCollectionItem('Proficiency', distributionNo);
  }

  public UpdateProficiencyBatch(distributionNo, data: ProficiencyBatch) {
    let dData: ProficiencyBatch = {
      ...data,
      adminEditor: {
        id: this.currentAdmin.uid,
        email: this.currentAdmin.email,
        name: this.currentAdmin.name,
        dateTime: new Date()
      }
    }
    return this.updateCollectionData('Proficiency', distributionNo, dData);
  }

  public getSystemVersions() {
    return this.getCollectionData('System', 'versions');
  }

  public createBackup(id: string, collection: string, isFacility: boolean) {
    let url: string;
    if (isFacility === true) {
      url = this.apiEP + `/testbackupFacility?id=SQACustomers/${id}`;
    } else {
      url = this.apiEP + `/testbackupFacility?id=${collection}/${id}`;
    }
    console.log(url);
    return new Promise((res, rej) => {
      firebase.auth().currentUser.getIdToken().then(async token => {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });

        this._http.get(url, { headers: headers }).toPromise().then(backup => {
          let ref: any;
          const myblob = new Blob([JSON.stringify(backup, null, 2)], { type: 'application/json' });
          console.log(myblob);
          let filename = new Date().toISOString() + '.json';
          const file = new File([myblob], filename, { type: 'application/json' });
          var storageRef = firebase.storage().ref();
          if (isFacility === true) {
            ref = storageRef.child(`Backups/${id}/${filename}`);
          } else {
            ref = storageRef.child(`Backups_SQA/collections/${collection}/${id}/${filename}`);
          }
          console.log('------------------>', ref)
          this.uploadBackup(file, ref).then(resurl => {
            console.log('resurl', resurl)
            const date = firebase.firestore.Timestamp.fromDate(new Date());
            if (isFacility === true) {
              this.updateCollectionData('SQACustomers', id, { lastBackup: date }).then(upRes => {
                console.log('last backup: ', upRes)
              })
            }
            res(resurl);
          }).catch(err => {
            console.log(err);
            rej(err);
          })
        }).catch(err => {
          rej(err);
        });
      });
    })
  }

  public getBackupStorageData() {
    return new Promise((res, rej) => {
      this._http.get(this.apiEP + '/getStorageData', httpOptions).toPromise().then(files => {
        res(files[0]);
      }).catch(err => {
        rej(err);
      })
    })
  }

  private uploadBackup(file, ref) {
    return new Promise<any>((res, rej) => {
      ref.put(file).then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          res(url);
        }).catch(err => {
          rej(err);
        });
      });
    });
  }

  public getBackupList(isFacility: boolean, collection: string, id: string) {
    var storageRef = firebase.storage().ref();
    if (isFacility === true) {
      var ref = storageRef.child(`Backups/${id}`);
    } else {
      var ref = storageRef.child(`Backups_SQA/collections/${collection}/${id}`);
    }
    return new Promise<any>((res, rej) => {
      ref.list().then(filelist => {
        let paths = filelist.items.map(item => {
          let path = item['location']['path'];
          let patharr = path.split('/');
          let filename = patharr[patharr.length - 1];
          let name = filename.split('.')[0];
          let date = new Date(name);
          return { fileName: filename.toString() };
        })
        res(paths);
      }).catch(err => {
        console.log(err);
        rej(err);
      })
    })
  }

  public RestoreGeneralData(collection: string, docId: string, fileName: string) {
    let storageRef = firebase.storage().ref();
    let backupFileRef = storageRef.child(`Backups_SQA/collections/${collection}/${docId}/${fileName}`);
    return new Promise((resolve, reject) => {
      backupFileRef.getDownloadURL().then(res => {
        firebase.auth().currentUser.getIdToken().then(async token => {
          const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
          this._http.get(res, { headers }).toPromise().then(contents => {
            this.iterateData(contents, collection);
            resolve('success');
          }).catch(err => reject(err));
        })
      })
    })
  }

  iterateData(file: any, restorePath?: string) {
    const dbBatch = this.afs.firestore.batch();
    let destinatiopnPath: string;
    let parsedTimestamp: any = null;
    let timeStampkey: string;
    const Timestamp = firebase.firestore.Timestamp;
    for (let [key, value] of Object.entries(file)) {
      if (typeof (value) === 'object') {
        destinatiopnPath = restorePath + '/' + key;
        if (value.hasOwnProperty('data')) {
          let convertedObject: any = {}
          for (let [dataKey, dataValue] of Object.entries(value['data'])) {
            if (typeof (dataValue) === 'object' && dataValue?.hasOwnProperty('_seconds') && dataValue?.hasOwnProperty('_nanoseconds')) {
              timeStampkey = dataKey;
              parsedTimestamp = new Timestamp(dataValue['_seconds'], dataValue['_nanoseconds']);
            }
          }
          if (parsedTimestamp) {
            convertedObject = { ...value['data'], [timeStampkey]: parsedTimestamp };
          } else {
            convertedObject = { ...value['data'] };

          }
          const documentRef = this.afs.doc(destinatiopnPath).ref;
          dbBatch.set(documentRef, convertedObject, { merge: true });
        }
        if (value.hasOwnProperty('collections')) {
          if (value['collections'].length > 0) {
            Object.values(value['collections']).forEach(subCollection => {
              this.iterateData(subCollection, destinatiopnPath);
            })
          }
        }
      }
      if (Array.isArray(value) && value.length > 0) {
        value.forEach(document => {
          this.iterateData(document, destinatiopnPath);
        })
      }
    }
    dbBatch.commit().then(out => {
      console.log('DATA RESTORED: ', out);
    })
  }

  public RestoreFacilityData(docId: string, fileName: string) {
    let timeStamp = firebase.firestore.Timestamp;
    let backupFileUrl: string;
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`Backups/${docId}/${fileName}`);
    ref.getDownloadURL().then(res => {
      backupFileUrl = res;
      const dbBatch = this.afs.firestore.batch();
      firebase.auth().currentUser.getIdToken().then(async token => {
        const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        this._http.get(backupFileUrl, { headers: headers }).toPromise().then(response => {
          Object.entries(response)[0].forEach(async item => {
            const mainDataFromFile = await item.data;
            const collDataFromFile = await item.collections;
            if (mainDataFromFile && collDataFromFile[0]) {
              console.log('Initiate restoring facility: ', mainDataFromFile.uid);
              const dataRestoreObject = {
                ...mainDataFromFile,
                creationTime: new timeStamp(mainDataFromFile.creationTime?._seconds, mainDataFromFile?.creationTime._nanoseconds),
                lastSignInTime: new timeStamp(mainDataFromFile.lastSignInTime?._seconds, mainDataFromFile?.lastSignInTime._nanoseconds),
                lastNotification: mainDataFromFile.lastNotification ? new timeStamp(mainDataFromFile.lastNotification?._seconds, mainDataFromFile.lastNotification?._nanoseconds) : null,
                lastBackup: new timeStamp(mainDataFromFile.lastBackup?._seconds, mainDataFromFile.lastBackup?._nanoseconds),
              }
              const facilityRef = this.afs.doc(`SQACustomers/${mainDataFromFile.uid}`).ref
              dbBatch.set(facilityRef, dataRestoreObject, { merge: true });
              if (Array.isArray(collDataFromFile)) {
                collDataFromFile.forEach(collection => {
                  for (let [collectionName, collectionData] of Object.entries(collection)) {
                    console.log('COLL:', collectionName, 'DATA: ', collectionData);
                    if (Array.isArray(collectionData)) {
                      collectionData.forEach(collectionDataItem => {
                        for (let [collectionDataID, collectionDataItemValue] of Object.entries(collectionDataItem)) {
                          const subCollDocRef = this.afs.doc(`SQACustomers/${mainDataFromFile.uid}/${collectionName}/${collectionDataID}`).ref
                          let newContents = {};
                          for (let [key, contents] of Object.entries(collectionDataItemValue)) {
                            newContents = { ...contents };
                            if (key === 'data') {
                              // console.log(`${key} from ${collectionName} document ${collectionDataID}`, contents)
                              Object.entries(contents).forEach((contentsItem) => {
                                const [_key, _value] = contentsItem
                                if (typeof (_value) === 'object') {
                                  let count: number = 0;
                                  for (let prop in _value) {
                                    if (_value.hasOwnProperty(prop)) {
                                      ++count;
                                    }
                                  }
                                  if (count == 2) {
                                    newContents[_key] = new timeStamp(_value['_seconds'], _value['_nanoseconds'])
                                  }
                                }
                              })
                              dbBatch.set(subCollDocRef, newContents, { merge: true })
                            }
                            if (key === 'collections') {
                              if (Array.isArray(contents) && contents.length > 0) {
                                contents.forEach(deepSubColl => {
                                  Object.values(deepSubColl).forEach(testItem => {
                                    if (Array.isArray(testItem)) {
                                      testItem.forEach(testData => {
                                        for (let [testId, testResults] of Object.entries(testData)) {
                                          const deepSubCollDocRef = this.afs.doc(`SQACustomers/${mainDataFromFile.uid}/${collectionName}/${collectionDataID}/tests/${testId}`).ref
                                          let newTestResultsContents = {};
                                          for (let [testResultsKey, testResultsValue] of Object.entries(testResults)) {
                                            newTestResultsContents = { ...testResultsValue }
                                            if (testResultsKey === 'data') {
                                              Object.entries(testResultsValue).forEach((testResultsItem) => {
                                                const [_key, _value] = testResultsItem;
                                                if (_key === 'dateTime') {
                                                  newTestResultsContents[_key] = new timeStamp(Object.values(_value)[0], Object.values(_value)[1])
                                                }
                                                if (_key === 'sampleInformation') {
                                                  newTestResultsContents[_key]['collectedTime'] = new timeStamp(+Object.values(_value['collectedTime'])[0], +Object.values(_value['collectedTime'])[1]);
                                                  newTestResultsContents[_key]['receivedTime'] = new timeStamp(+Object.values(_value['receivedTime'])[0], +Object.values(_value['receivedTime'])[1]);
                                                }
                                                if (_key === 'visualizationImages') {
                                                  let newImagesArray = [];
                                                  if (Array.isArray(_value)) {
                                                    _value.forEach(image => {
                                                      const newImageItem = {
                                                        ...image,
                                                        capturedDate: new timeStamp(image['capturedDate']['_seconds'], image['capturedDate']['_nanoseconds'])
                                                      };
                                                      image = newImageItem;
                                                      newImagesArray.push(image);
                                                    })
                                                  }
                                                  newTestResultsContents[_key] = newImagesArray;
                                                }
                                                if (_key === 'visualizationVideos') {
                                                  let newVideosArray = [];
                                                  if (Array.isArray(_value)) {
                                                    _value.forEach(video => {
                                                      const newVideoItem = {
                                                        ...video,
                                                        capturedDate: new timeStamp(video['capturedDate']['_seconds'], video['capturedDate']['_nanoseconds'])
                                                      };
                                                      video = newVideoItem;
                                                      newVideosArray.push(video);
                                                    })
                                                  }
                                                  newTestResultsContents[_key] = newVideosArray;
                                                }
                                              })
                                              dbBatch.set(deepSubCollDocRef, newTestResultsContents, { merge: true })
                                            }
                                          }
                                        }
                                      })
                                    }
                                  })
                                })
                              }
                            }
                          }
                        }
                      })
                    }
                  }
                })
              }
              dbBatch.commit().then(output => {
                console.log('Restore is DONE!!!!', output);
              })
            }
          });
        }).catch(err => console.log(err));
      })
    });
  }

  public restoreFromLocalFile(isFacility: boolean, file: File, documentId: string, collectionName?: string) {
    return new Promise<any>((res, rej) => {
      let storageRef = firebase.storage().ref();
      if (isFacility === true) {
        var ref = storageRef.child(`Backups/${documentId}/${file.name}`);
      } else {
        var ref = storageRef.child(`Backups_SQA/collections/${collectionName}/${documentId}/${file.name}`);
      }
      this.uploadBackup(file, ref).then(upRes => {
        res(upRes)
      }).catch(upErr => {
        rej(upErr);
      })
    })
  }

  public downloadBackFile(isFacility: boolean, collectionName: string, docId: string, fileName: string) {
    var storageRef = firebase.storage().ref();
    if (isFacility === true) {
      console.log((`Backups/${docId}/${fileName}`));
      var ref = storageRef.child(`Backups/${docId}/${fileName}`);
    } else {
      console.log((`Backups/collections/${collectionName}${docId}/${fileName}`));
      var ref = storageRef.child(`Backups_SQA/collections/${collectionName}/${docId}/${fileName}`);

    }
    return new Promise<any>((res, rej) => {
      ref.getDownloadURL().then(url => {
        res(url);
      }).catch(err => {
        rej(err);
      }
      )
    })
  }

  public deleteBackupFile(isFacility: boolean, collectionName: string, docId: string, fileName: string) {
    var storageRef = firebase.storage().ref();
    if (isFacility === true) {
      var ref = storageRef.child(`Backups/${docId}/${fileName}`).fullPath
    } else {
      var ref = storageRef.child(`Backups_SQA/collections/${collectionName}/${docId}/${fileName}`).fullPath;
    }
    firebase.auth()
    return new Promise<any>((res, rej) => {
      this.deleteFileFromStorage(ref).then(result => {
        console.log('Files Deleted', result);
        res(result)
      }).catch(err => {
        console.log(err);
        rej(err);
      })
    })
  }

  public signalQC(data, signalType, parameters?): Observable<any> {
    //const url = "http://localhost:8080/signalQC"
    //const url = "https://signalprocessing-ldoxyjbqhq-uc.a.run.app/signalQC"
    const url = "https://signalqc.mes-data.app/signalQC"
    const headers = { 'content-type': 'application/json' }

    //console.log("signalQC Parameters from http service ",parameters)

    let buf = {}
    if (parameters == undefined) {
      buf = { 'data': data, 'signaltype': signalType };
    } else {
      buf = { 'data': data, 'signaltype': signalType, 'Parameters': parameters };
    }
    const body = JSON.stringify(buf);
    return this._http.post(url, body, { 'headers': headers }).pipe(
      take(1),
      catchError(error => {
        console.error('Signal QC failed ' ,error);
        return throwError(error);
      })
    );
  }


  // public signalQC(data, signalType, parameters?) {
  //   //const url = "http://localhost:8080/signalQC"
  //   const url = "https://signalprocessing-ldoxyjbqhq-uc.a.run.app/signalQC"
  //   const headers = { 'content-type': 'application/json' }


  //   let buf = {}
  //   if (parameters == undefined) {
  //     buf = { 'data': data, 'signaltype': signalType };
  //   } else {
  //     buf = { 'data': data, 'signaltype': signalType, 'Parameters': parameters };
  //   }
  //   const body = JSON.stringify(buf);

  //   return this._http.post(url, body, { 'headers': headers });
  // }

  public plotGraph(data): Observable<any> {
    const url = 'https://algserver-na6h4zb5uq-uc.a.run.app/getdata';
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    console.log(body)
    return this._http.post(url, body, { 'headers': headers })
  }

  // Convert a row of data from datatable of measurements
  //  into 3 json objects - od, average and count
  public convertRowDataToJSON(rowData, rowLevel) {

    let od_json = {
      'level': rowLevel,
      'parameter': 'od',
      'devices': []
    };

    let average_json = {
      'level': rowLevel,
      'parameter': 'average',
      'devices': []
    };

    let count_json = {
      'level': rowLevel,
      'parameter': 'count',
      'devices': []
    };

    let row_entries = Object.entries<string>(rowData).filter(([key, value]) => key.includes('Value'));

    row_entries.forEach(([key, values]) => {

      let deviceID = key.split('_')[1];
      let parameter = key.split('_')[2];

      let jsonObj = (parameter == 'OD') ? od_json : (parameter == 'AVG') ? average_json : count_json;

      values.split(' <br/> ').forEach((val, index) => {
        jsonObj.devices[index] = { ...jsonObj.devices[index], [deviceID]: val };
      });

    });

    return { od_json, average_json, count_json };

  }

  public getAllTestCreditsByFilterPagination(status: string, typeArr, creatorIsMe: boolean) {
    let lastDocRef;
    const limit = 20
    return () => {
      return new Promise<any>((res, rej) => {
        let markers = [];
        if (creatorIsMe) {

          let query = this.db.collection('Credits').where('creator.adminId', '==', this.currentAdmin.uid).where('status', '==', status).where('type', 'in', typeArr)
          if (lastDocRef)
            query = query.startAfter(lastDocRef).limit(limit)
          else
            query = query.limit(limit)
          query.get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              // if (doc.data().facilityId === id) {
              lastDocRef = doc
              markers.push({ id: doc.id, data: doc.data() });
              // }
            });
            console.log("markers", markers);

            res(markers);
          }).catch(err => {
            rej(err);
          })
        } else {
          console.log("came here", lastDocRef, status, typeArr);

          let query = this.db.collection('Credits').where('status', '==', status).where('type', 'in', typeArr)
          console.log(query);

          if (lastDocRef) {
            query = query.startAfter(lastDocRef).limit(limit)
          } else {
            query = query.limit(limit)
          }
          console.log(query);

          query.get().then(querySnapshot => {
            console.log("snapshot length", querySnapshot.size);

            querySnapshot.forEach(doc => {
              console.log(doc);

              // if (doc.data().facilityId === id) {
              lastDocRef = doc
              markers.push({ id: doc.id, data: doc.data() });
              // }
            });
            res(markers);
          }).catch(err => {
            rej(err);
          })
        }
      })
    }
  }
  public getAllDataByFilterPagination(type:any,path) {
    let lastDocRef;
    const limit = 10
    return () => {
      return new Promise<any>((res, rej) => {
        let markers = [];
       

          let query = this.db.collection(path).where('type', '==', type)
          if (lastDocRef)
            query = query.startAfter(lastDocRef).limit(limit)
          else
            query = query.limit(limit)
          query.get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
              // if (doc.data().facilityId === id) {
              lastDocRef = doc
              markers.push({ id: doc.id, data: doc.data() });
              // }
            });
            console.log("markers", markers);

            res(markers);
          }).catch(err => {
            rej(err);
          })
         
      })
    }
  }



}


