import { Pipe, PipeTransform } from "@angular/core";
import { HttpService } from "../services/http.service";
import { GlobalService } from "../services/global.service";

@Pipe({
    name: 'loopValue',
})
export class looppipe implements PipeTransform {
    constructor(private _http: HttpService, private global: GlobalService) { }

    transform(value: any, routingDevice: string, deviceNum: string, rountingComment: string,) {
        // console.log("---------------00", value, deviceNum, routingDevice, rountingComment,);
        let array = [];

        return new Promise((resp, rej) => {
            for (const item of value) {
                this._http.getCollectionData(`${routingDevice}${deviceNum}${rountingComment}`, item).then((data: any) => {
                    if (data.id === item) {
                        array.push(`Comment\u00A0#${++this.global.CommentCount}:\u00A0\u00A0${data.data.commentsStatus}.\u00A0${data.data.commentsTextData}`)
                        // console.log("[[[[[[[[[[[[[[[[[[", data, item,array);
                        resp(array)
                    }
                })
            }
        })
    }
}
