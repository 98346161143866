import { AppError } from './app-error';

export class FailedLoginError extends AppError {
    authError = {
        code: 'auth/too-many-requests',
        message: 'Too many failed login attempts. Please try again in a few minutes. '
    };
    constructor(public originalError?: any) {
        super(originalError);
        this.originalError = this.authError;

    }
}
