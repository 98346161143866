import { RouteObject } from "src/app/globals/globals";

export const SYSTEM_ROUTES: Array<RouteObject> = [
      { childRoute: 'all-admins', title: 'MES-Admins' },
      { childRoute: 'edit-permissions', title: 'Edit Permissions' },
      { childRoute: 'facility-backup', title: 'Facility Backup' },
      { childRoute: 'general-backup', title: 'General Backup' },
      { childRoute: 'custom-reports', title: 'Custom Reports' },
];

export const PROD_UTILITIES: Array<RouteObject> = [
      { childRoute: 'all-test-credits', title: 'Test Credits' },
      { childRoute: 'bulk-creation', title: 'Create Credits Bulk' },
      { childRoute: 'all-credit-bulks', title: 'Test Credit Bulks' },
      { childRoute: 'all-reg-numbers', title: 'Registration Numbers' },
      { childRoute: 'create-reg-bulk', title: 'Create Registration Bulk' },
      { childRoute: 'register-number-batches', title: 'Registration Bulks' },
      { childRoute: 'qc-controls', title: 'QC Controls' },
      { childRoute: 'proficiency', title: 'Proficiency' },
];

export const PROD_PROCESS: Array<RouteObject> = [
      { childRoute: 'precalibration', title: 'Pre Calibration' },
      { childRoute: 'postproduction', title: 'Post Production' },
      { childRoute: 'iocalibration', title: 'IO Calibration' },
      { childRoute: 'ioverification', title: 'IO Verification' },
      { childRoute: 'postcalibration', title: 'Post Calibration' },
      { childRoute: 'finalqc', title: 'Final QC' },
      { childRoute: 'controlcalibration', title: 'Control' },
      { childRoute: 'signalfilter', title: 'Signal Filter' },
];

export const PROD_PROCESS_OTHER: Array<RouteObject> = [
      { childRoute: 'calibration', title: 'Calibration' },
      { childRoute: 'verification', title: 'Verification' },
];

export const PROD_PROCESS_VU: Array<RouteObject> = [
      { childRoute: 'vu-assembly', title: 'VU Assembly' },
      { childRoute: 'vu-postproduction', title: 'VU Post Production' },
      { childRoute: 'vu-finalqc', title: 'VU Final QC' },
];
9
export const PROD_PROCESS_DOCKING_STATION = [
      { childRoute: 'docking-station-assembly', title: 'Assembly' },
      { childRoute: 'docking-station-postproduction', title: 'Post Production' },
];

export const PROD_TOOLS: Array<RouteObject> = [
      { childRoute: 'calibrations', title: 'Calibrations' },
      { childRoute: 'clinicalanalysis', title: 'Clinical Analysis' },
      { childRoute: 'proficiencyalg', title: 'Proficiency Alg' },
];

export const DEVICES: Array<RouteObject> = [
      { childRoute: 'devices', title: 'Devices' },
      { childRoute: 'vudevices', title: 'VU Devices' },
      { childRoute: 'docking-station-batches', title :'Docking Station Batches' }
];

export const REPORTS: Array<RouteObject> = [
      { childRoute: 'reports', title: 'Old Reports' },
      { childRoute: 'ioroutinghistory', title: 'iO Routing History' },
      { childRoute: 'vuroutinghistory', title: 'VU Routing History' },
      { childRoute: 'ds-routing-history', title: 'D. Station Routing History' },
];

export const SERVICE: Array<RouteObject> = [
      { childRoute: 'analysis', title: 'Analysis' },
      { childRoute: 'automation', title: 'Automation' },
      { childRoute: 'filtercalibration', title: 'Filter Calibration' },
      { childRoute: 'controlref1', title: 'Control Ref 1' },
];

export const DATA_ANALYSIS: Array<RouteObject> = [
      { childRoute: 'dataanalysis', title: 'Data Analysis' },
      { childRoute: 'videoanalysis', title: 'Video Analysis' },
      { childRoute: 'deviceanalysis', title: 'Device Analysis' },
      { childRoute: 'devicemap', title: 'Device Map' },
      { childRoute: 'qc-data-analysis', title: 'QC Data Analysis' }
];

export const CONNECTORS: Array<RouteObject> = [
      { childRoute: 'testfilesmanager', title: 'FileManagement' },  
      { childRoute: 'scriptmanager', title: "ScriptManager" },  

];
