import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';


const routes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'distributers',
    //     loadChildren: () => import('./distributers/distributer.module').then(m => m.DistributerModule),
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'notifications',
    //     loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'production-process',
        loadChildren: () => import('./production-process/production-process.module').then(m => m.ProductionProcessModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'production-process-vu',
        loadChildren: () => import('./production-process-vu/production-process-vu.module').then(m => m.ProductionProcessVUModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'production-process-docking-station',
        loadChildren: () => import('./production-process-docking/production-process-docking.module').then(m => m.ProductionProcessDockingModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'production-tools',
        loadChildren: () => import('./production-tools/production-tools.module').then(m => m.ProductionToolsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'devices',
        loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
    },

    {
        path: 'production',
        loadChildren: () => import('./production/production.module').then(m => m.ProductionModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'sementest',
        loadChildren: () => import('./sementest/sementest.module').then(m => m.SemenTestModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'data-analysis',
        loadChildren: () => import('./data-analysis/data-analysis.module').then(m => m.DataAnalysisModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'connectors',
        loadChildren: () => import('./connectors/connectors.module').then(m => m.ConnectorsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'system',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },

    {
        path: 'production-utilities',
        loadChildren: () => import('./ProductionUtilities/production-utilities.module').then(m => m.ProductionUtilitiesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'widget',
        loadChildren: () => import('./widget/widget.module').then(m => m.WidgetModule),
        canActivate: [AuthGuard]
    },
   
    {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: '',
        redirectTo: 'authentication',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
