import { AppError } from './app-error';

export class WrongPasswordError extends AppError {
    authError = {
        code: 'auth/wrong-password',
        message: 'The email and password combination does not match.'
    };
    constructor(public originalError?: any) {
        super(originalError);
        this.originalError = this.authError;

    }

}
