import { CommonModule } from '@angular/common';
import { Component, OnInit, Injectable, NgModule, ViewEncapsulation } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';

export class FilterEvent {
  facilityTypes: string[];
}

declare const $: any;

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.sass'],
  encapsulation: ViewEncapsulation.None
})

@Injectable({
  providedIn: 'root'
})


export class RightSidebarComponent implements OnInit {

  // public onFilterChange: EventEmitter<any> = new EventEmitter<any>();
  filterContent: Subject<any> = new Subject<any>();
  filteringData: any = [];
  countryList: any = []
  multiCountry:any = new FormControl();
  filterSubscription: Subscription;
  // toppingList = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  selectedCountry: any = []
  filteredData: any = {};
  allcustomers: any[];
  isEntered: boolean = false
  constructor(private _glob: GlobalService) {  }

  ngOnInit() {
    this.setSkinListHeightAndScroll(true);
    this.skinChanger();
    this.changeSidebarColor();
    this.changeDarkLightTheme();
    this.setSettingListHeightAndScroll(true);
  }


  setSkinListHeightAndScroll(isFirstTime) {
    var height =
      $(window).height() -
      ($(".navbar").innerHeight() + $(".right-sidebar .nav-tabs").outerHeight());
    var $el = $(".right-sidebar .demo-skin");

    if (!isFirstTime) {
      $el.slimScroll({ destroy: true }).height("auto");
      $el
        .parent()
        .find(".slimScrollBar, .slimScrollRail")
        .remove();
    }

    $el.slimscroll({
      height: height + "px",
      color: "rgba(0,0,0,0.5)",
      size: "6px",
      alwaysVisible: false,
      borderRadius: "0",
      railBorderRadius: "0"
    });
  }
  skinChanger() {
    $(".right-sidebar .demo-choose-skin li").on("click", function () {
      var $body = $("body");
      var $this = $(this);

      var existTheme = $(".right-sidebar .demo-choose-skin li.actived").data(
        "theme"
      );
      $(".right-sidebar .demo-choose-skin li").removeClass("actived");
      $body.removeClass("theme-" + existTheme);
      $this.addClass("actived");

      $body.addClass("theme-" + $this.data("theme"));
      var choose_skin = "theme-" + $this.data("theme");
      localStorage.setItem("choose_skin", choose_skin);
      localStorage.setItem("choose_skin_active", $this.data("theme"));
    });
  }

  changeSidebarColor() {
    $(".rightSetting .btn-sidebar-light").on("click", function () {
      $("body").removeClass("menu_dark logo-black");
      $("body").addClass("menu_light logo-white");
      var menu_option = "menu_light";
      localStorage.setItem("choose_logoheader", "logo-white");
      localStorage.setItem("menu_option", menu_option);
    }),
      $(".rightSetting .btn-sidebar-dark").on("click", function () {
        $("body").removeClass("menu_light logo-white");
        $("body").addClass("menu_dark logo-black");
        var menu_option = "menu_dark";
        localStorage.setItem("choose_logoheader", "logo-black");
        localStorage.setItem("menu_option", menu_option);
      });
  }

  changeDarkLightTheme() {
    $(".rightSetting .btn-theme-light").on("click", function () {
      $("body").removeClass("dark submenu-closed menu_dark logo-black theme-black");
      $("body").addClass("light submenu-closed menu_light logo-white theme-white");

      $(".choose-theme li").removeClass("actived");
      $(".choose-theme li[data-theme|='white']").addClass("actived");

      var theme = "light";
      var menu_option = "menu_light";
      localStorage.setItem("choose_logoheader", "logo-white");
      localStorage.setItem("choose_skin", "theme-white");
      localStorage.setItem("theme", theme);
      localStorage.setItem("menu_option", menu_option);
    }),
      $(".rightSetting .btn-theme-dark").on("click", function () {
        $("body").removeClass("light submenu-closed menu_light logo-white theme-white");
        $("body").addClass("dark submenu-closed menu_dark logo-black theme-black");

        $(".choose-theme li").removeClass("actived");
        $(".choose-theme li[data-theme|='black']").addClass("actived");

        var theme = "dark";
        var menu_option = "menu_dark";
        localStorage.setItem("choose_logoheader", "logo-black");
        localStorage.setItem("choose_skin", "theme-black");
        localStorage.setItem("theme", theme);
        localStorage.setItem("menu_option", menu_option);
      });

  }

  setSettingListHeightAndScroll(isFirstTime) {
    var height =
      $(window).height() -
      ($(".navbar").innerHeight() + $(".right-sidebar .nav-tabs").outerHeight());
    var $el = $(".right-sidebar .demo-settings");

    if (!isFirstTime) {
      $el.slimScroll({ destroy: true }).height("auto");
      $el
        .parent()
        .find(".slimScrollBar, .slimScrollRail")
        .remove();
    }

    $el.slimscroll({
      height: height + "px",
      color: "rgba(0,0,0,0.5)",
      size: "6px",
      alwaysVisible: false,
      borderRadius: "0",
      railBorderRadius: "0"
    });
  }
  remove(arr, what) {
    var found = arr.indexOf(what);

    while (found !== -1) {
      arr.splice(found, 1);
      found = arr.indexOf(what);
    }
  }
  



  setRightSidebar() {
    var _this = this;
    var $sidebar = $("#rightsidebar");
    var $overlay = $(".overlay");

    //Close sidebar
    // $(window).on("click", function (e) {
    //   var $target = $(e.target);
    //   console.log("{{{{{{{{", e,$(e.target).parent(),$target.hasClass("js-right-sidebar"));

    // if (e.target.nodeName.toLowerCase() == null) {
    // $target = $(e.target).parent();
    //   $sidebar.toggleClass("open");
    //   $overlay.fadeIn();
    // }

    //   if (!$target.hasClass("js-right-sidebar") &&_this.isOpen() && $target.parents("#rightsidebar").length === 0) {
    //     if (!$target.hasClass("bars")) $overlay.fadeOut();
    //     $sidebar.removeClass("open");
    //   }
    // });
    // $(".js-right-sidebar").on("click", function () {
    //   console.log("{{{{{{{{",_this.isOpen());
    // $sidebar.toggleClass("open");
    //     if (_this.isOpen()) {
    //       $overlay.fadeIn();
    //     } else {
    //       $overlay.fadeOut();
    //     }
    //   });
  }

  isOpen() {
    return $(".right-sidebar").hasClass("open");
  }

}
