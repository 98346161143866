import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/globals/user';
import { HttpService } from 'src/app/services/http.service';
import { CommunicatorService, MyServiceEvent } from 'src/app/services/communication.service';

const document: any = window.document;
declare const $: any;
declare const jQuery: any;
declare const mozFullScreenElement: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {

  photo: string = 'assets/images/SQA-iO-Logo.png';
  adminName: string = '';

  subscription: Subscription;
  driverVersion: any;
  isUpdateDriver: boolean = false;
  toolText: any = "";
  isDriverInstalled: boolean = false;
  _serviceSubscription: Subscription;
  constructor(public authService: AuthService, private comm: CommunicatorService, private _http: HttpService) {


    this.subscription = authService.user.subscribe((user) => {
      console.log('current user ', user);
      if (user !== undefined && user !== null) {
        this.adminName = (user.firstName + ' ' + user.lastName);
      }
    });
    this._serviceSubscription = this.comm.onChange.subscribe({
      next: (event: MyServiceEvent) => {

        switch (event.command) {
          case 'isConnect':
            // console.log("Driver connection change ", event.value);
            if (event.value === true) {
              this.isDriverInstalled = true;
              this.checkDriverStatus()
            } else {
              this.isDriverInstalled = false;
              this.checkDriverStatus()
            }
            break;
        }
      }
    })
  }

  ngOnInit() {
    this.setStartupStyles();
    this.callFullScreen();
    this.setNavbar();
    this.setRightSidebar();

    $(".navbar-right .dropdown-menu .body .menu").slimscroll({
      height: "254px",
      color: "rgba(0,0,0,0.5)",
      size: "4px",
      alwaysVisible: false,
      borderRadius: "0",
      railBorderRadius: "0"
    });
  }
  ngAfterViewInit() {
    this.checkDriverStatus();
  }

  checkDriverStatus() {
    console.log("checkDriverStatus", this.isDriverInstalled);
    if (this.isDriverInstalled) {
      let params = this.comm.getParameters();
      if (params !== undefined) {
        this.driverVersion = params.OBServiceVersion;
      }
      console.log("driverVersion", this.driverVersion);

      if (this.driverVersion == undefined) {
        this.isUpdateDriver = true;
        //this.toolText = "A new driver version is available";
      } else {
        this.checkForUpdateVersion()
      }
    } else {
      this.isUpdateDriver = true;
    }


    // this.comm.onChange.subscribe({
    //   next: (event: MyServiceEvent) => {
    //     switch (event.command) {
    //       case 'isConnect':
    //         if (this.comm.IsDriverConnected() == false) {
    //           console.log("Driver is not connected");
    //         } else {
    //           console.log("Driver is connected");
    //         }
    //       break;
    //     }
    //   }
    // });

  }

  checkForUpdateVersion() {
    this._http.getSystemVersions().then((data: any) => {
      if (data.data.driver == this.driverVersion) {
        console.log("same version");
        this.toolText = "";
        this.isUpdateDriver = false;
      } else {
        console.log(" not Same versio");
        this.toolText = "A new driver version is available";
        this.isUpdateDriver = true;
      }
    })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this._serviceSubscription.unsubscribe()
  }

  setStartupStyles() {
    //set theme on startup
    if (localStorage.getItem("theme")) {
      $("body").removeClass("dark light");
      jQuery("body").addClass(localStorage.getItem("theme"));
    } else {
      jQuery("body").addClass("light");
    }

    // set dark sidebar menu on startup
    if (localStorage.getItem("menu_option")) {
      jQuery("body").addClass(localStorage.getItem("menu_option"));
    }
    else {
      jQuery("body").addClass("menu_light");
    }

    // set header color on startup
    if (localStorage.getItem("choose_skin")) {
      jQuery("body").addClass(localStorage.getItem("choose_skin"));
    } else {
      jQuery("body").addClass("theme-white");
    }
    if (localStorage.getItem("choose_skin_active")) {
      $(".right-sidebar .demo-choose-skin li").each(function (index) {
        jQuery(this).removeClass("actived");
        if (
          jQuery(this).attr("data-theme") ==
          localStorage.getItem("choose_skin_active")
        ) {
          jQuery(this).addClass("actived");
        }
      });
    }
    // set logo color on startup
    if (localStorage.getItem("choose_logoheader")) {
      jQuery("body").addClass(localStorage.getItem("choose_logoheader"));
    } else {
      jQuery("body").addClass("logo-white");
    }
    if (localStorage.getItem("choose_logoheader_active")) {
      $(".right-sidebar .demo-choose-logoheader li").each(function (index) {
        jQuery(this).removeClass("actived");
        if (
          jQuery(this).attr("data-theme") ==
          localStorage.getItem("choose_logoheader_active")
        ) {
          jQuery(this).addClass("actived");
        }
      });
    }

    //set theme default color
    $(".choose-theme li").removeClass("actived");
    $(".choose-theme li[data-theme|='white']").addClass("actived");

  }

  callFullScreen() {
    $(document).on("click", ".fullscreen-btn", function (e) {
      if (
        !document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    });
  }

  setNavbar() {
    var $body = $("body");
    var $overlay = $(".overlay");

    //Open left sidebar panel
    $(".bars").on("click", function () {
      $body.toggleClass("overlay-open");
      if ($body.hasClass("overlay-open")) {
        $overlay.fadeIn();
      } else {
        $overlay.fadeOut();
      }
    });

    //Close collapse bar on click event
    $('.nav [data-close="true"]').on("click", function () {
      var isVisible = $(".navbar-toggle").is(":visible");
      var $navbarCollapse = $(".navbar-collapse");

      if (isVisible) {
        $navbarCollapse.slideUp(function () {
          $navbarCollapse.removeClass("in").removeAttr("style");
        });
      }
    });
  }

  setRightSidebar() {
    var _this = this;
    var $sidebar = $("#rightsidebar");
    var $overlay = $(".overlay");
    var onCheck = false;
    //Close sidebar

    $(window).on("click", function (e) {
      var $target = $(e.target);
      if (e.target.nodeName.toLowerCase() === "i") {
        $target = $(e.target).parent();
      }
      if (e.target.nodeName == "SPAN" || e.target.nodeName == "INPUT" || e.target.nodeName == "ASIDE" || e.target.nodeName.includes("MAT")) {
        onCheck = true
      } else {
        onCheck = false
      }
      console.log("OOOOO", _this.isOpen(), onCheck);
      if (!$target.hasClass("js-right-sidebar") && _this.isOpen() && $target.parents("#rightsidebar").length === 0) {
        if (onCheck)
          $overlay.fadeOut();

      }
    });
    $(".js-right-sidebar").on("click", function () {
      $sidebar.toggleClass("open");
      if (_this.isOpen() && !onCheck) {
        $overlay.fadeIn();
      } else {
        $overlay.fadeOut();
      }
    });
  }

  isOpen() {
    return $(".right-sidebar").hasClass("open");
  }

  logout() {
    this.authService.signOut();
  }


  //Download Driver

  downloadDriver() {
    let driverurl = "Installation/Service/SQA-iO_Setup.exe";
    // if (this.isUpdateDriver) {
    this._http.getFileUrl(driverurl).then((data: any) => {
      this.downloadMyFile(data);
    });
    //}
  }
  downloadMyFile(data) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', data);
    link.setAttribute('download', `products.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
