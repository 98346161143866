import { AppError } from './app-error';

export class NotFoundError extends AppError {
    authError = {
        code: 'auth/user-not-found',
        message: 'Your account could not be found. Please check your email and try again.'
    };
    constructor(public originalError?: any) {
        super(originalError);
        this.originalError = this.authError;

    }
}
