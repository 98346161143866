import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  cAdminType: any;
  _permissions: any;
  plist: Subscription;
  
  constructor(private authService: AuthService, private afs: AngularFirestore) {
    this.authService.user.subscribe(userInfo=>{
      this.plist = this.afs.doc(`System/permissions/AdminSite/${userInfo.adminType}`).valueChanges().subscribe(res=>{
        this._permissions = res;
        console.log('Permissions loaded');
      });
    })
  }
}
