
export const Customer = {
    currentFacilityID: null
};

export let CurrentUser = {
    uid: '',
    email: '',
    photoURL: '',
    doctorName: '',
    facilityName: '',
    phone: '',
    website: '',
    country: '',
    city: '',
    zipCode: '',
    streetNo: '',
    testCredits: 0,
    designation: '',
    optional1: '',
    optional2: '',
    testSettings: {},
};

export interface Admin {
    uid?: string;
    firstName?: string;
    email?: string;
    lastName?: string;
    adminType?: string;
    dateAdded?: string;
    lastActive? :string;
    activeStatus?: boolean;
}

export interface SQACustomer {
    photoURL?: string;
    logoURL?: string;
    doctorName?: string;
    facilityName?: string;
    phone?: string;
    website?: string;
    country?: string;
    city?: string;
    zipCode?: string;
    streetNo?: string;
    testCredits?: number;
    designation?: string;
    optional1?: string;
    optional2?: string;
    testSettings?: TestSettings;
    deviceSN?: string;
    patientCount?: number;
    testCount?: number;
    requestCount?: number;
    customerType?: string;
    lastMaintenance?: DeviceMaintenance;
    creationTime?: Date;
    lastSignInTime?: Date;
    totalCredits?: number;
    servicePersons?: {
        id: string;
        role?: string;
        name?: string;
        location?: string;
        mesDistributer?: string;
        fwEmail?: string;
        support?: boolean;
        order?: boolean;
    }[];
    geoCountry?: string;
    geoCity?: string;
}

export interface TestSettings {
    settingsid?: string;
    concentrationStd?: number;
    who?: number;
    testOptional1?: string;
    testOptional2?: string;
    facilityOptional1?: string;
    facilityOptional2?: string;
    patientAge?: string;
    debrisAssessment?: boolean;
    patientUnits?: string;
    weightUnit?: string;
    heightUnit?: string;
    referenceSettings?: References;
}

export interface Patient {
    patientid?: string;
    firstName?: string;
    lastName?: string;
    birthDate?: any;
    weight?: number;
    height?: number;
    email?: string;
    mobile_phone?: string;
    home_phone?: string;
    address?: string;
    addedDate?: Date;
    facilityId?: string;
    facilityName?: string;
    testCount?: number;
}

export interface Test {
    dateTime?: Date;
    tester?: string;
    designation?: string;
    referringDoc?: string;
    testType?: string;
    aw?: number;
    amplitude?: number;
    average?: number;
    averageId?: number;
    conc?: number;
    count?: number;
    controlRef1?: number;
    ledCurrent1?: number;
    ledCurrent2?: number;
    msc?: number;
    maxSpWidth?: number;
    minSpWidth?: number;
    noiseLevel?: number;
    normalMorph?: number;
    od?: number;
    odRef?: number;
    ref1?: number;
    ref2?: number;
    smiThreshold?: number;
    serialNumber?: number;
    zeroLevel?: number;
    comments?: string;
    sampleInformation?: SampleInformation;
    testSettings?: TestSettings;
    concentration?: number;
    totalMotilePRNP?: number;
    progressivePR?: number;
    normalForms?: number;
    motileSpermConc?: number;
    progMotileSpermConc?: number;
    spermMotilityIndex?: number;
    spermCount?: number;
    motileSperm?: number;
    progMotileSperm?: number;
    motility?: number;
    rapidProgressiveA?: number;
    slowProgressiveB?: number;
    progMotileSpermConcA?: number;
    progMotileSpermConcB?: number;
    immotile?: number;
    non_progressive_np?: number;
    hostname?: string;
    facilityId?: string;
    facilityName?: string;
    patientId?: string;
    patientFirstName?: string;
    patientLastName?: string;
}

export interface SampleInformation {
    sampleId?: string;
    sampleType?: number;
    patientAge?: number;
    debris?: number;
    wbcConcentration?: number;
    pH?: number;
    volume?: number;
    appearance?: string;
    viscosity?: string;
    liquefaction?: string;
    abstinence?: number;
    collectedTime?: Date;
    receivedTime?: Date;
    optional1?: string;
    optional2?: string;
    testType?: number;
}

export interface TestCredit {
    credit?: number;
    status?: string;
    creator?: {
        adminEmail: string;
        adminId: string;
    };
    creationDate?: Date;
    usingFacility?: {
        facilityName: string;
        facilityId: string;
    };
    redeemingDate?: Date;
    redeemedBy?: {
        email: string;
        id: string;
        userType: string;
    };
    type?: string;
    batchId?: string;
    printLock?: boolean;
    creditDeviceType?:string;
}

export interface RegistrationNumber {
    status?: string;
    creator?: {
        adminEmail: string;
        adminId: string;
    };
    creationDate?: Date;
    usingFacility?: {
        facilityName: string;
        facilityId: string;
    };
    registrationDate?: Date;
    redeemingDate?: Date;
    redeemedBy?: {
        email: string;
        id: string;
        userType: string;
    };
    type?: string;
    iOwMode?:boolean;
    batchId?: string;
}

export interface CreditBatch {
    creator?: {
        adminEmail: string;
        adminId: string;
    };
    type?: string;
    creditNumber?: number;
    creditAmount?: number;
    printLock?: boolean;
    creditDeviceType?:string;
}

export interface RegisterNumberBatch {
    creator?: {
        adminEmail: string;
        adminId: string;
    };
    type?: string;
    registrationAmount?: number;
    printLock?: boolean;
    iOwMode:boolean; 
}

export interface Request {
    supportId?: string;
    facilityId: string;
    facilityName?: string;
    requesterName?: string;
    requesterEmail?: string;
    requesterPhone?: string;
    requestType?: string;
    message?: string;
    dateTime?: Date;
    status?: string; // has to be set to 'new' on sending a support ticket through contact us
    notes?: string;
}

export interface References {
    concentration?: (string | number)[];
    motility?: (string | number)[];
    total_motile_pr_np?: (string | number)[];
    // progressive_pr?: (string | number)[];
    prog_motile_sperm_conc?: (string | number)[];
    prog_motile_sperm_conc_a?: (string | number)[];
    prog_motile_sperm_conc_b?: (string | number)[];
    // rapid_progressive_a?:(string | number)[];
    // slow_progressive_b?: (string | number)[];
    // immotile?: (string | number)[];
    // non_progressive_np?: (string | number)[];
    normal_forms?: (string | number)[];
    total_sperm_count?: (string | number)[];
    // sperm_motility_index?: (string | number)[];
    motile_sperm_conc?: (string | number)[];
    motile_sperm?: (string | number)[];
    // prog_motile_sperm?: (string | number)[];
    use_WHO_RefValue_def?: boolean;
}

export interface DeviceMaintenance {
    date?: Date;
    deviceSN?: string;
    facilityId?: string;
    facilityName?: string;
    supplyInventory?: boolean;
    cleanChamber?: boolean;
    dryChamber?: boolean;
    dustChamber?: boolean;
    passedSelfTestConfirm?: boolean;
}

export interface Device {
    calibration?: any;
    lastMaintenance?: Date;
    usingFacility?: {
        facilityName?: string;
        facilityId?: string;
        facilityAddress?: any;
    };
    testCount?: number;
    selfTestCount?: number;
}

export interface ServiceData {
    serviceDataDate?: Date;
    operatorId?: string;
    operatorName?: string;
    serviceDataParameters: any;
    serviceDataType?: string;
    platform?: string;
}

export interface Notification {
    notificationType?: string;
    title?: string;
    body?: string;
    display?: any[];
    active?: boolean;
    createdTimestamp?: Date;
    updatedTimestamp?: Date;
    saveForLater?: boolean;
}

export interface Distributer {
    // code?: string;
    // mainDistributer?: string;
    // subDistributer?: {
    //     name: string;
    //     location: string;
    // };
    // orderEmail?: string;
    // supportEmail?: string;
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    companyName?: string;
    mesDistributer?: {
        id: string;
        name: string;
        country: string;
    }
    country?: string;
    city?: string;
    role?: string;
    type?: string;
    optional?: string;
}

export interface ControlsBatch {
    batchID?: string;
    adminEditor?: {
        name: string,
        id: string,
        email: string,
        dateTime: Date
    }
    editLock?: boolean;
    lots?: ControlsLot[];
    expirationDate?: string;
}

export interface ControlsLot {
    lotID?: string;
    level?: number;
    isNegativeControl?: boolean;
    // expirationDate?: Date;
    target?: number;
    value?: number;
}

export interface ProficiencyBatch {
    distributionNo?: string;
    adminEditor?: {
        name: string,
        id: string,
        email: string,
        dateTime: Date
    }
    submissionDeadline?: string;
    samples?: ProficiencySample[];
    scheme?: string;
  }
  
  export interface ProficiencySample {
    id?: string;
    sampleId?: string;
    distributionNo?: string;
    issueDate?: string;
    testDateTime?: Date;
    refConc?: number;
    chamberStd?: number;
    wbcConcStd?: number;
    sampleType?: number;
    testType?: number;
    rawData?: any;
    calRawData?: any;
    results?: any;
    note?: string;
    scheme?: string;
    conc?: number;
    facilityId?: string;
    facilityName?: string;
    userId?: string;
    userName?: string;
    submissionDeadline?: string;
    adminNote?: string;
    algResult?: number;
  }
  

export interface RouteObject {
    routeForHide? :boolean,
    childRoute: string,
    title: string
  }